import type React from 'react';
import type { Theme } from '@mui/material';
import { useTheme, Box } from '@mui/material';


export type variants  = 'roundPurple'  | 'default' | 'empty' | 'outline'
interface TheButtonProps {
  children?: React.ReactNode;
  type?: 'button' | 'submit';
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  variant?: variants;
}
const variants = (theme: Theme, variant: string) => {
  const variantStyles = {
    roundPurple:{
      background: theme.palette.brand.primary.main,
      "&:hover": {
        background: theme.palette.brand.primary.light
      },
      borderRadius: '1rem',
      padding: '0.5rem 1rem',
    },
    default:{},
    outline:{},
  }
  if(!variant){
    return {}
  }
  return variantStyles[variant]
}

const defaultStyles = (props: TheButtonProps, theme: Theme)=> ({
  border: 'none',
  color:'#fff',
  fontSize: 'inherit',
  cursor: props?.disabled ? 'not-allowed' : 'pointer',
  ...variants(theme, props.variant ?? 'default')
})

export default function TheButton({children, onClick, ...props}: TheButtonProps) {
  const theme: Theme = useTheme();
  const type = props.type ?? 'button';
  const className = props?.className ?? ''
    return (
        <Box
          component='button'
          disabled={props.disabled}
          sx={{ ...defaultStyles(props, theme), ...props.style}}
          onClick={onClick}
          className={className}
          type={type}
        >
          {children}
        </Box>
    )
}
