import { createContext, useState, useRef, MutableRefObject, useContext, useMemo } from "react";
import { VListHandle } from "virtua";
import { SetStateType } from "@/utils";
import { Item } from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { useElectric } from "@/electric/ElectricWrapper";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { format } from "date-fns";

type FeedStatusMessage = {
  message: string;
  severity: "error" | "success" | "info";
};

export interface FullItem {
  id: string;
  feedId: string;
  contentId: string;
  groupId?: string;
  deletedAt?: string;
  createdAt: string;
  accountId: string;
  status: string;
  mine: boolean;
  label: string;
  key: string;
  feedItemIndex: number;
  lastActiveItem: boolean;
  isFirstDateGroupItem: boolean;
  dateGroupLabel: string;
  lastActiveItemIdIsMine: boolean;
  shortDateStr: string;
  loadedContent: number;
  url?: string;
  isSilent?: number;
  unread?: number;
}

type IndexedItem = Item & {
  shortDateStr: string;
  formattedTime: string;
  feedItemIndex: number;
};

export type FeedState = {
  vListRef?: MutableRefObject<VListHandle>;
  fileUploadModal?: boolean;
  status?: FeedStatusMessage;
  templatesModal?: boolean;
  itemTranscriptState?: string[];
  setItemTranscriptState?: SetStateType<string[]>;
  itemMessageDetailsState?: Record<string, boolean>;
  scrollToBottomOfFeed?: () => void;
  setFileUploadModal: SetStateType<boolean>;
  setItemMessageDetailsState?: SetStateType<Record<string, boolean>>;
  setTemplatesModal: SetStateType<boolean>;
  setStatus: SetStateType<FeedStatusMessage>;
  items?: Item[]
  feedMap?: FullItem[];
};

export const FeedContext = createContext<FeedState>({
  scrollToBottomOfFeed: () => { },
  setFileUploadModal: () => { },
  setTemplatesModal: () => { },
  setItemMessageDetailsState: () => { },
  setStatus: () => { },
});

const FeedContextProvider = ({ children }) => {
  // context use to persist state in the feed virtualized list until refactor occurs
  const [templatesModal, setTemplatesModal] = useState<boolean>(false);
  const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
  const [itemTranscriptState, setItemTranscriptState] = useState<string[]>([]);
  const [itemMessageDetailsState, setItemMessageDetailsState] = useState<
    Record<string, boolean>
  >({});
  const [status, setStatus] = useState<FeedStatusMessage>(null);
  const vListRef = useRef<VListHandle>(null);

  const scrollToBottomOfFeed = () => {
    if (vListRef?.current) {
      vListRef.current?.scrollTo(vListRef.current?.scrollSize);
    }
  };
  const { myAccount } = useContext(MyAccountContext);

  const { currentFeedId } = useContext(CurrentFeedContext);
  const { db } = useElectric();

  const { results: items } = useLiveQuery(() => {
    if (!currentFeedId) return;
    return db.item.liveMany({
      where: {
        feedId: currentFeedId,
        deletedAt: null,
      },
      orderBy: {
        createdAt: "asc",
      },
    });
  }, [currentFeedId]);


  const feedMap = useMemo(() => {
    const dateFormat = (publishedDate) => {
      const date = new Date(publishedDate);
      const dateFormat = "MMM d, yyyy";
      const shortDateStr = format(date, "MMddyyyy");
      const formattedDate = format(date, dateFormat);
      const formattedTime = format(date, "h:mm aa");
      return {
        shortDateStr,
        formattedDate,
        formattedTime,
      };
    };

    const indexedItems = new Map<string, IndexedItem[]>();

    items?.forEach((feedItem, index) => {
      const { shortDateStr, formattedTime } = dateFormat(feedItem.createdAt);

      const item = {
        ...feedItem,
        shortDateStr,
        feedItemIndex: index,
        formattedTime,
      };
      if (indexedItems.has(shortDateStr)) {
        indexedItems.set(shortDateStr, [
          ...indexedItems.get(shortDateStr),
          item,
        ]);
      } else {
        indexedItems.set(shortDateStr, [item]);
      }
    });

    const ret =
      items?.map((feedItem, index): FullItem => {
        const { shortDateStr, formattedTime } = dateFormat(feedItem.createdAt);

        const firstItemForDate = indexedItems.get(shortDateStr)?.[0];

        const firstItem = dateFormat(firstItemForDate.createdAt);

        const isFirstDateGroupItem = firstItemForDate.id === feedItem.id;
        const dailyLabel = firstItem.formattedDate;
        const dateGroupLabel = isFirstDateGroupItem ? dailyLabel : "";

        const lastActiveItem = items[items.length - 1]?.id === feedItem.id;

        const lastActiveItemIdIsMine =
          lastActiveItem && feedItem?.accountId === myAccount?.id;
        return {
          id: feedItem.id,
          feedId: feedItem.feedId,
          contentId: feedItem.contentId,
          groupId: feedItem.groupId,
          deletedAt: feedItem.deletedAt,
          createdAt: feedItem.createdAt,
          accountId: feedItem.accountId,
          status: feedItem.status,
          mine: feedItem.accountId === myAccount?.id,
          unread: feedItem.unread,
          label: `${dailyLabel} @ ${formattedTime}`,
          key: feedItem.createdAt,
          isFirstDateGroupItem,
          dateGroupLabel,
          feedItemIndex: index++,
          lastActiveItem,
          lastActiveItemIdIsMine,
          shortDateStr,
          loadedContent: feedItem.loadedContent,
          url: `${window?.location?.href?.split("#")[0]}#${feedItem?.id}` || "",
          ...feedItem,
        };
      }) || [];
    return ret;
  }, [items, myAccount?.id]);



  const feedState: FeedState = {
    vListRef,
    fileUploadModal,
    status,
    templatesModal,
    itemTranscriptState,
    setItemTranscriptState,
    itemMessageDetailsState,
    scrollToBottomOfFeed,
    setItemMessageDetailsState,
    setFileUploadModal,
    setTemplatesModal,
    setStatus,
    items,
    feedMap,
  };
  return (
    <FeedContext.Provider value={feedState}>{children}</FeedContext.Provider>
  );
};

export default FeedContextProvider;
