import { upsertDirectWsInvitation, upsertWorkspaceMembership, upsertWsAccount } from "@/data/workspace";
import { useElectric } from "@/electric/ElectricWrapper";
import type { Workspace, WorkspaceMembership } from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";
import React, { createContext, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../AppStateProvider";
import { MyAccountContext } from "./myAccountProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import UseTimeouts from "@/hooks/useTimeouts";

export type WorkspaceState = {
  currentWorkspaceId?: string;
  myCurrentWorkspaceMembership?: WorkspaceMembership;
  workspaceMemberships?: WorkspaceMembership[];
  isWorkspaceAdmin?: () => boolean;
  isWorkspaceLimitedMember?: () => boolean;
  workspaces?: Workspace[];
}

export const WorkspaceContext = createContext<WorkspaceState>({});

const LOCATION_ENABLED = true;


const WorkspaceProvider = ({ children }) => {
  const { db } = useElectric();
  const { client } = useContext(AppContext);
  const { myAccountId } = React.useContext(MyAccountContext);
  const { workspaceId } = useParams();
  const { sendDesktopHandsFree } = useFlags();
  const handsFreeAllowed = handsFreeEnabledForWorkspace(workspaceId);

  // Onboarding requires a workspace ID for workspace provider to work
  const { results: firstWorkspace } = useLiveQuery(() => {
    return db.workspace.liveFirst({});
  }, []);

  const { results: myCurrentWorkspaceMembership } = useLiveQuery(() => {
    if ((!myAccountId || !workspaceId) && !firstWorkspace?.id) return;
    const workspaceIdFirstWorkspaceId = workspaceId ?? firstWorkspace?.id
    return db.workspace_membership.liveFirst({
      where: {
        workspaceId: workspaceIdFirstWorkspaceId,
        accountId: myAccountId,
        status: "active",
      },
    });
  }, [myAccountId, workspaceId, firstWorkspace?.id]);

  const { results: workspaceMemberships } = useLiveQuery(() => {
    if (!workspaceId && !firstWorkspace?.id) return;
    const workspaceIdFirstWorkspaceId = workspaceId ?? firstWorkspace?.id
    return db.workspace_membership.liveMany({
      where: {
        status: "active",
        workspaceId: workspaceIdFirstWorkspaceId,
      },
    });
  }, [workspaceId, firstWorkspace?.id]);

  // TODO - limit these to my active memberships
  const { results: workspaces } = useLiveQuery(db.workspace.liveMany({
    orderBy: {
      name: "asc",
    },
  }));

  const isWorkspaceAdmin = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "admin";
  }, [myCurrentWorkspaceMembership]);

  const isWorkspaceLimitedMember = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "limitedMember";
  }, [myCurrentWorkspaceMembership]);


  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const f = async () => {
      try {
        const membership = await db.workspace_membership.findFirst({
          where: {
            accountId: myAccountId,
            workspaceId,
            status: "active",
          },
        });
        if (
          !myAccountId ||
          !workspaceId ||
          membership?.role === "limitedMember"
        )
          return;
        await client.getWorkspaceInvitations(workspaceId).then((resp) => {
          for (const membership of resp?.workspaceMemberships || []) {
            upsertWorkspaceMembership(db, membership);
          }
          for (const account of resp?.accounts || []) {
            upsertWsAccount(db, account);
          }
          for (const directInvitation of resp?.directInvitations || []) {
            upsertDirectWsInvitation(db, directInvitation);
          }
        });
      } catch (e) {
        console.error("Error loading workspace details", e);
      }
    };
    f();
  },
    [myAccountId, workspaceId],
  );



  const localHeartBeat = UseTimeouts(60);
  useEffect(() => {
    if (!handsFreeAllowed || !sendDesktopHandsFree) return;
    if (localHeartBeat?.refresh === false) {
      if (sendDesktopHandsFree && LOCATION_ENABLED) {
        const successCallback = (position: {
          coords: {
            latitude: number;
            longitude: number;
          };
        }) => {
          const timestamp = new Date().toISOString();
          const heartbeat = {
            timestamp,
            appContext: {
              handsFreeEnabled: true,
            },
            deviceContext: {
              location: {
                latitude: position?.coords?.latitude ?? null,
                longitude: position?.coords?.longitude ?? null,
                timestamp,
              },
            },
          };
          client.heartbeat(workspaceId, heartbeat);
        };
        const errorCallback = () => {
          const timestamp = new Date().toISOString();
          const heartBeatNoGeo = {
            timestamp,
            appContext: {},
          };
          client.heartbeat(workspaceId, heartBeatNoGeo);
        };

        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback,
        );
      } else if (sendDesktopHandsFree) {
        const timestamp = new Date().toISOString();
        const heartBeatNoGeo = {
          timestamp,
          appContext: {
            handsFreeEnabled: true,
          },
        };
        client.heartbeat(workspaceId, heartBeatNoGeo);
      }
    }
  }, [
    handsFreeAllowed,
    localHeartBeat?.refresh,
    sendDesktopHandsFree,
    workspaceId,
  ]);

  return (
    <WorkspaceContext.Provider value={{
      currentWorkspaceId: workspaceId,
      myCurrentWorkspaceMembership,
      workspaceMemberships,
      workspaces,

      isWorkspaceAdmin,
      isWorkspaceLimitedMember,
    }}>
      {children}
    </WorkspaceContext.Provider>
  );
}

export default WorkspaceProvider;
