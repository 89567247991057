import {
  Button,
  IconButton,
  Box,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AudioFileOutlined,
  ImageOutlined,
  DescriptionOutlined,
} from "@mui/icons-material";
import CalendarClock from "@/components/Icons/CalendarClock";
import NoteEditOutlined from "@/components/Icons/NoteEditOutlined";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { format } from "date-fns";
import { useContext, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import EditScheduledMessage from "../Scheduling/EditScheduledMessage";
import { WsWorkflowItem } from "web-client/api/data-contracts";
import ModalForm from "@/elements/ModalForm";
import TrashOutlined from "../Icons/TrashOutlined";
import { ActionContext } from "@/models/ActionsProvider";
import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { LoadingButton } from "@mui/lab";
import { parseCronScheduledDate, selectTranscription } from "@/utils";
import { formatInTimeZone } from "date-fns-tz";
import { DataContext } from "@/models/DataProvider";
import ChannelDialOutlined from "@/components/Icons/ChannelDialOutlined";
import { getChannelsWithPermissions } from "@/data/liveQueries/channelsWithPermissions";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";

export default function WorkflowScheduledItem({
  workflowItem,
}: { workflowItem: WsWorkflowItem }) {
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [editMessageModalOpen, setEditMessageModalOpen] =
    useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const { deleteWorkflowItem } = useContext(ActionContext);
  const { preferredLanguage } = useContext(DataContext);
  const { myAccountId } = useContext(MyAccountContext)
  const { timezone } = useContext(SchedulingContext);
  const { db } = useElectric();
  const theme = useTheme();
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);
  const contentId = workflowItem?.contentId;

  //   const { results: permissionToEdit } = useLiveQuery(() => {
  //     if (!myAccountId || !workflowItem?.id) return;
  //     return db.permission.liveFirst({
  //       where: {
  //         workflowItemId: workflowItem?.id,
  //         accountId: myAccountId,
  //       },
  //     });
  //   }, [myAccountId, workflowItem]);

  const { results: audioContents } = useLiveQuery(() => {
    if (!contentId) return;
    return db.audio_encoding.liveMany({
      where: {
        contentId,
      },
    });
  }, [contentId]);

  const { results: transcriptions } = useLiveQuery(() => {
    if (!contentId) return;
    return db.transcription.liveMany({
      where: {
        contentId,
        transcriptionType: "full",
      },
    });
  }, [contentId]);

  const { results: rawFiles } = useLiveQuery(() => {
    if (!contentId) return;
    return db.file.liveMany({
      where: {
        contentId,
      },
    });
  }, [contentId]);

  const { results: displayArtifact } = useLiveQuery(() => {
    if (!contentId) return;
    return db.display_artifact.liveFirst({
      where: {
        contentId,
      },
    });
  }, [contentId]);

  const { results: broadcastAction } = useLiveQuery(() => {
    if (!workflowItem?.id) return;
    return db.broadcast_action.liveFirst({
      where: {
        workflowItemId: workflowItem?.id,
      },
    });
  }, [workflowItem]);

  const { results: scheduledTrigger } = useLiveQuery(() => {
    if (!broadcastAction?.id) return;

    return db.schedule_trigger.liveFirst({
      where: {
        broadcastActionId: broadcastAction?.id,
      },
    });
  }, [broadcastAction]);

  const { results: myMembership } = useLiveQuery(() => {
    if (!workspaceId || !myAccountId) return;
    return db.workspace_membership.liveFirst({
      where: {
        workspaceId: workspaceId,
        accountId: myAccountId,
        status: "active",
      },
    });
  }, [workspaceId, myAccountId]);

  const { results: broadcastRecipients } = useLiveQuery(() => {
    if (!broadcastAction?.broadcastId) return;
    return db.broadcast_recipient.liveMany({
      where: {
        broadcastId: broadcastAction?.broadcastId,
      },
    });
  }, [broadcastAction]);

  const { channelsWithPermissions: channels } = getChannelsWithPermissions(
    useLiveQuery,
    db,
    { workspaceId, myMembership },
  );

  const selectedChannels = useMemo(() => {
    if (broadcastRecipients?.length > 0) {
      const broadcastChannels = broadcastRecipients.map((br) => br.feedId);
      const currentSelectedChannels = channels
        ?.filter((c) => broadcastChannels.includes(c?.id))
        ?.map((channel) => {
          return { id: channel?.id, title: channel?.title };
        });
      return currentSelectedChannels;
    }
    return [];
  }, [broadcastRecipients, channels]);

  const scheduledDate = useMemo(() => {
    if (scheduledTrigger?.oneTimeSchedule) {
      return format(
        new Date(scheduledTrigger?.oneTimeSchedule),
        "MMM dd, yyyy 'at' hh:mm aa",
      );
    }

    if (scheduledTrigger?.cronSchedule) {
      const parsedDate = parseCronScheduledDate(
        scheduledTrigger?.cronSchedule,
        scheduledTrigger?.timezone,
      );
      if (parsedDate) {
        return formatInTimeZone(
          parsedDate,
          timezone || "",
          "'Every' iiii 'at' hh:mm aa",
        );
      }
    }

    return "";
  }, [scheduledTrigger, timezone]);

  const { simpleHtml, richTranscript, textTranscript } = selectTranscription(
    preferredLanguage,
    transcriptions,
  );
  const transcriptionContent = simpleHtml || richTranscript || textTranscript;

  const file = rawFiles?.[0];
  const fileType = () => {
    if (file) {
      return file?.mimeType.split("/")?.[0];
    }
    return transcriptionContent ? "text" : "";
  };

  const fileIcon = () => {
    let icon;
    switch (fileType()) {
      // may include this in the future
      // case "audio":
      // 	icon = <AudioFileOutlined role="presentation" />;
      // 	break;
      case "image":
        icon = <ImageOutlined role="presentation" />;
        break;
      case "text":
        icon = <NoteEditOutlined role="presentation" />;
        break;
      default:
        icon = <DescriptionOutlined role="presentation" />;
        break;
    }
    return icon;
  };

  const noChannelsStyles = () => {
    if (selectedChannels?.length > 0) return;
    return {
      padding: "0 12px 0 12px",
      background: theme.palette.error.main,
      borderRadius: 100,
    };
  };

  const handleEditMessage = (value) => {
    setEditMessageModalOpen(value);
  };

  const handleConfirmDelete = async () => {
    try {
      setDeleting(true);
      await deleteWorkflowItem(workspaceId, workflowItem?.id);
    } catch (e) {
      console.log(e);
    } finally {
      setDeleting(false);
      setShowDelete(false);
    }
  };

  return (
    <Stack
      className="scheduled-message-element"
      sx={{
        width: "100%",
        py: 1.5,
        px: 2,
        background: theme.palette.secondary.main,
        borderRadius: 1.5,
        gap: 1,
        "&:hover": {
          background: theme.palette.secondary.light,
        },
      }}
    >
      <Typography variant="h6" component="h3">
        {workflowItem?.displayName}
      </Typography>
      {displayArtifact?.description ? (
        <Box>
          <Link
            component="button"
            sx={{ fontWeight: 600 }}
            onClick={() => setShowDescription(true)}
          >
            Description
          </Link>
          <ModalForm
            open={showDescription}
            onClose={() => setShowDescription(false)}
          >
            <Stack sx={{ width: "100%", gap: 1.25 }}>
              <Typography variant="h6" component="h3" sx={{ fontWeight: 700 }}>
                Description
              </Typography>
              <div
                id={`${contentId}-description`}
                role="textbox"
                style={{ wordBreak: "break-word" }}
                dangerouslySetInnerHTML={{
                  __html: displayArtifact?.description,
                }}
              />
            </Stack>
          </ModalForm>
        </Box>
      ) : null}
      {file?.name || transcriptionContent ? (
        <Stack sx={{ gap: 1, flexDirection: "row", flexWrap: "nowrap" }}>
          <div>{fileIcon()}</div>
          {file?.name ? (
            <Typography
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {file?.name}
            </Typography>
          ) : null}
          {transcriptionContent ? (
            <div
              id={`${contentId}-transcription`}
              role="textbox"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxHeight: "38px",
              }}
              dangerouslySetInnerHTML={{
                __html: transcriptionContent?.transcriptionContent,
              }}
            />
          ) : null}
        </Stack>
      ) : null}
      <Stack
        sx={{
          gap: 1,
          flexDirection: "row",
          flexWrap: "no-wrap",
          width: "max-content",
        }}
      >
        <CalendarClock role="presentation" />
        <Typography sx={{ fontWeight: 600 }}>{scheduledDate}</Typography>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Stack
          sx={{ alignItems: "center", flexDirection: "row", gap: 1 }}
          className="text-ellipsis"
        >
          <Box sx={{ mt: 0.5 }}>
            <ChannelDialOutlined />
          </Box>
          <Typography sx={{ fontWeight: 600, ...noChannelsStyles() }}>
            {selectedChannels?.length > 0
              ? selectedChannels?.map((sc) => sc?.title).join(", ")
              : "No channels"}
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignSelf: "flex-end", gap: 1.5 }}>
          <EditScheduledMessage
            workflowItem={workflowItem}
            broadcastId={broadcastAction?.broadcastId}
            editMessageModalOpen={editMessageModalOpen}
            openEditMessageModalHandler={handleEditMessage}
          />
          <IconButton color="primary" onClick={() => setShowDelete(true)}>
            <TrashOutlined role="img" />
          </IconButton>
        </Stack>
        <ModalForm
          open={showDelete}
          onClose={() => setShowDelete(false)}
          disableClose={deleting}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              textAlign: "center",
              gap: 2,
            }}
          >
            <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
              Delete Scheduled Message
            </Typography>
            <Typography sx={{ fontWeight: 600, maxWidth: 300 }}>
              Are you sure you want to delete this scheduled message?
            </Typography>
            <Stack
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                width: "100%",
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                sx={{ order: { xs: 1, sm: 0 } }}
                onClick={() => setShowDelete(false)}
                disabled={deleting}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={deleting}
                variant="contained"
                color="error"
                sx={{ order: { xs: 0, sm: 1 } }}
                onClick={handleConfirmDelete}
              >
                Delete
              </LoadingButton>
            </Stack>
          </Stack>
        </ModalForm>
      </Stack>
    </Stack>
  );
}
