import ScheduleCalendar from "@/components/Icons/ScheduleCalendar";
import InvalidUser from "@/components/InvalidUser";
import Loading from "@/components/Loading/Index";
import WorkflowItems from "@/components/Workflows/WorkflowItems";
import { DataContext } from "@/models/DataProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { UxContext } from "@/models/UxStateProvider";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Workflows() {
  const { setScheduleMessageModalOpen, setNavTitle } = useContext(UxContext);
  const { loadWorkspaceWorkflowItems, availableWorkspaceRoles } =
    useContext(DataContext);
  const { myAccountId } = useContext(MyAccountContext);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const theme = useTheme();

  useEffect(() => {
    const f = async () => {
      setLoadingItems(true);
      try {
        if (workspaceId && myAccountId) {
          await loadWorkspaceWorkflowItems(workspaceId);
        }
      } catch (e) {
        console.log("error fetching workflow items", e);
      } finally {
        setLoadingItems(false);
      }
    };
    f();
  }, [workspaceId, myAccountId, loadWorkspaceWorkflowItems]);

  useEffect(() => {
    setNavTitle("Scheduled Messages");
  }, [setNavTitle]);

  return (
    <>
      <InvalidUser
        validRoles={[
          availableWorkspaceRoles?.get("admin")?.role,
          availableWorkspaceRoles?.get("member")?.role,
        ]}
      />
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
          pt: 8,
          px: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            maxWidth: 1300,
            alignItems: "center",
            gap: 2,
            overflow: "hidden",
          }}
        >
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "flex-start", sm: "center" },
              justifyContent: { xs: "flex-start", sm: "space-between" },
              width: "100%",
              gap: 2,
            }}
          >
            <Typography
              component="h3"
              sx={{ fontSize: "1.75rem", fontWeight: 700 }}
            >
              Scheduled Messages
            </Typography>
            <Box>
              <Button
                color="primary"
                variant="contained"
                sx={{ px: 6, gap: 1 }}
                onClick={() => setScheduleMessageModalOpen(true)}
              >
                <ScheduleCalendar /> New Scheduled Message
              </Button>
            </Box>
          </Stack>
          <Divider
            sx={{
              width: "100%",
              borderWidth: "0.75px",
              borderColor: theme.palette.secondary.light,
              mt: 3,
              mb: 2,
            }}
          />
          {loadingItems ? (
            <Stack sx={{ alignItems: "center", width: "100%" }}>
              <Loading />
            </Stack>
          ) : (
            <WorkflowItems />
          )}
        </Stack>
      </Stack>
    </>
  );
}
