import { useElectric } from "@/electric/ElectricWrapper";
import { AudioEncoding, Electric } from "@/generated/client";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { browserIsSafari } from "@/utils";
import { targetCodecForBrowser } from "@/utils/audio";
import { selectAudio } from "@/utils/contentSelection";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useBeforeUnload } from "react-router-dom";
import { AllPreferredLanguage, PreferredLanguage } from "./languages";

function createSourceElement(filename: string) {
  const contentType = "audio/ogg";
  const src = filename;
  const srcElement = document.createElement("source");
  srcElement.type = contentType;
  srcElement.src = src;
  return srcElement;
}

function createSafariSourceElement(filename: string) {
  const contentType = `audio/x-caf; codecs="opus"`;
  const src = filename;
  const srcElement = document.createElement("source");
  srcElement.type = contentType;
  srcElement.src = src;
  return srcElement;
}

const loadAudioLibrary = async () => {
  if (document !== undefined) {
    try {
      console.log("loading audio library");
      // @ts-ignore
      return await import("@storyboard-fm/audio-core-library").then(
        (module) => module,
      );
    } catch (e) {
      console.error("loadAudioLibrary", e);
    }
  } else {
    console.log("document is undefined, cannot load audio library");
  }
};

const audioElement = new Audio();

export type AudioState = {
  audioCtx?: AudioContext;
  audioElement?: HTMLAudioElement;
  activeItemId?: string;
  setActiveItemId: (value: string) => void;
  pauseItem: () => void;
  playItem: () => void;
  playerPlaying: boolean;
};

export const AudioAppContext = createContext<AudioState>({
  audioElement,
  setActiveItemId: () => { },
  activeItemId: null,
  pauseItem: () => { },
  playItem: () => { },
  playerPlaying: false,
});

const mimeTypeOverride = {
  opus: "audio/ogg; codecs='opus'",
  caf: "audio/x-caf; codecs='opus'",
};

function sourcesFromItem(audioSources: AudioEncoding[]) {
  const sourceUrls = [];
  const opus = audioSources.filter((c) => c.codec === "opus")[0];
  const caf = audioSources.filter((c) => c.codec === "caf")[0];
  const mp3 = audioSources.filter((c) => c.codec === "mp3")[0];
  for (const af of [mp3, opus, caf]) {
    if (af) {
      sourceUrls.push({
        src: af.url,
        type:
          af.codec in mimeTypeOverride
            ? mimeTypeOverride[af.codec]
            : af.mimeType,
      });
    }
  }
  const sources = sourceUrls.map((src) => {
    const source = document.createElement("source");
    source.setAttribute("src", src.src);
    if (src.type) {
      source.setAttribute("type", src.type);
    }
    return source;
  });
  return sources;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
  handsFreeModeFlag: boolean;
};

function clearSources() {
  if (audioElement.hasChildNodes()) {
    const children = audioElement.childNodes;
    for (const child of children) {
      audioElement.removeChild(child);
    }
  }
  if (browserIsSafari(navigator.userAgent)) {
    audioElement.src = "";
  }
}

async function loadAudioSources(db: Electric["db"], activeItemId: string) {
  try {
    if (!activeItemId) {
      console.log("No active item");
      return;
    }
    const myAccount = await db.account.findFirst({
      where: {
        mine: 1,
      },
    });

    const item = await db.item.findFirst({
      where: {
        id: activeItemId,
      },
    });

    const preferredLanguage = (myAccount?.preferredLanguage ||
      "none") as PreferredLanguage;

    if (!item) {
      console.log("No item");
      return;
    }

    const audioContents = await db.audio_encoding.findMany({
      where: {
        contentId: item?.contentId,
        codec: targetCodecForBrowser(),
      },
    });

    const originalTranscription = await db.transcription.findFirst({
      where: {
        contentId: item?.contentId,
        translatedFrom: null,
      },
    });
    const inputLanguage =
      originalTranscription?.language as AllPreferredLanguage;

    console.log({
      audioContents,
    });
    const audioContent = selectAudio(
      preferredLanguage,
      audioContents,
      inputLanguage,
    );

    if (audioContent.length === 0) {
      console.log("No audio sources, skipping item");
      return;
    }

    const sources = sourcesFromItem(audioContent);

    let srcElement;
    let srcUrl;

    if (browserIsSafari(navigator.userAgent)) {
      // Safari source element
      try {
        srcUrl = sources.filter((cs) => cs.src?.endsWith("caf") === true)?.[0]
          .src;
      } catch (err) {
        srcUrl = sources?.[0].src?.replace(".mp3", ".caf");
      }
      srcElement = createSafariSourceElement(srcUrl);
    } else {
      // Source element for all non-Safari browsers
      srcUrl = sources[0]?.src;
      srcElement = createSourceElement(srcUrl);
    }

    try {
      clearSources();
      // No matter what UA we are we still append the source element
      audioElement.appendChild(srcElement);

      if (browserIsSafari(navigator.userAgent)) {
        audioElement.preload = "none"; // usually Safari ignores what you set here anyway
        // Safari needs this to be set explicitly as well
        audioElement.src = srcUrl;
      } else {
        // here we test only appending the source element for non-Safari
        audioElement.appendChild(srcElement);
      }
      // muted before audio is loaded for safari
      audioElement.muted = true;
      audioElement.load();
    } catch (e) {
      console.error("** err thrown during loadAudioSources func", e);
    }
  } catch (e) {
    console.error("Could not load audio", e);
  }
}

const AudioAppContextProvider = ({ children, handsFreeModeFlag }: Props) => {
  const { db } = useElectric();
  const [audioCtx, setAudioCtx] = React.useState<AudioContext>();

  const [playerPlaying, setPlayerPlaying] = React.useState<boolean>(false);
  const [activeItemId, setActiveItemId] = React.useState<string>();

  const resetPlayer = useCallback(async () => {
    setActiveItemId(null)
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
      clearSources();
      audioElement.load();
    }
  }, []);

  useEffect(() => {
    loadAudioLibrary()
      ?.then(() => {
        const audioCtx = new AudioContext();
        console.log("audioCtx", audioCtx);
        audioCtx.toggle();
        setAudioCtx(audioCtx);
      })
      .catch((e) => console.error("Error loading audio library", e));
  }, []);

  const playItem = useCallback(() => {
    if (!audioElement) return;
    audioElement.muted = false;
    const play = audioElement.play();
    if (play !== undefined) {
      play
        .then((_) => {
          // automatic playback started
          setPlayerPlaying(true);
        })
        .catch((error) => {
          // Auto-play was prevented
          setPlayerPlaying(false);
        });
    }
  }, []);

  const pauseItem = useCallback(() => {
    if (!audioElement) return;
    audioElement.pause();
    setPlayerPlaying(false);
  }, []);

  useEffect(() => {
    if (audioElement) {
      audioElement.onended = () => {
        setPlayerPlaying(false);
        setActiveItemId(null);
        clearSources();
      };
    }
  }, []);

  useEffect(() => {
    const f = async () => {
      if (activeItemId) {
        await loadAudioSources(db, activeItemId);
        playItem();
      } else {
        resetPlayer();
      }
    };
    f();
  }, [activeItemId]);

  useBeforeUnload(() => {
    resetPlayer();
  });

  const audioState: AudioState = {
    audioElement,
    setActiveItemId,
    activeItemId,
    pauseItem,
    playItem,
    playerPlaying,
  };

  return (
    <AudioAppContext.Provider value={audioState}>
      {children}
    </AudioAppContext.Provider>
  );
};

export default AudioAppContextProvider;
