import { FullTooltip, StyledChipsInput } from "@/components/Utils";
import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { PhoneService, chunkWithMinSize } from "@/utils";
import * as Icons from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Papa from "papaparse";
import { useCallback, useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { useStep } from "usehooks-ts";
import isEmail from "validator/lib/isEmail";
import { VList } from "virtua";
import {
  CreateWorkspaceDirectInvitationResponse,
  WorkspaceInvitation,
} from "web-client/api/data-contracts";

export default function InviteToWorkspaceModal() {
  const theme = useTheme();
  const { db } = useElectric();
  const { limitedMemberRole } = useFlags();
  const { ampli } = useContext(TrackingContext);
  const { sendWorkspaceInvites, addWorkspaceMembersToFeed } =
    useContext(ActionContext);
  const { inviteModalOpen, setInviteModalOpen } = useContext(UxContext);
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const [parsing, setParsing] = useState<boolean>(false);
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);
  const { currentFeedId: feedId } = useContext(CurrentFeedContext)
  const csvUploadRef = useRef(null);
  const defaultRole = limitedMemberRole ? "limitedMember" : "member";

  const phoneService = PhoneService();
  const modalPages = [];
  // max steps of 2
  const [currentStep, helpers] = useStep(2);
  const { reset: resetSteps, setStep } = helpers;

  const isValidEmail = (email: string) => {
    return isEmail(email);
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    return phoneService?.isValidNumber(phoneNumber);
  };

  const [error, setError] = useState(false);
  const [selectedCsv, setSelectedCsv] = useState<File>(null);
  const [csvError, setCSVError] = useState(null);
  const [errorText, setErrorText] = useState<string>();
  const [processing, setProcessing] = useState(false);
  const [contacts, setContacts] = useState<string[]>([]);
  const [invitations, setInvitations] = useState<WorkspaceInvitation[]>([]);
  const [invalidInvitations, setInvalidInvitations] = useState<
    WorkspaceInvitation[]
  >([]);
  const defaultHelperText = "Press enter to complete your entry.";
  const [contactHelperText, setContactHelperText] =
    useState<string>(defaultHelperText);
  const invitesCount = contacts?.length || invitations?.length;
  const totalInvites = invitesCount + invalidInvitations?.length;
  const emails = contacts?.filter((contact) => isValidEmail(contact));
  const phoneNumbers = contacts
    ?.filter((contact) => isValidPhoneNumber(contact))
    .map((number) => {
      //if a number doesn't contain a country code, then default to US
      if (!number.includes("+")) {
        return `+1${number}`;
      }
      return number;
    });

  const mailErrorText = "Something went wrong, please try again later.";

  const { results: workspace } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.workspace.liveUnique({
      where: {
        id: workspaceId,
      },
    });
  }, [workspaceId]);

  const toggleSendEmail = useCallback(() => {
    let toggled: boolean;
    setSendEmail((prev) => {
      toggled = !prev;
      if (toggled) {
        ampli.activateEmailSend();
      } else {
        ampli.deactivateEmailSend();
      }
      return toggled;
    });
    if (invitations.length > 0) {
      setInvitations((prev) => {
        return prev.map((invite) => {
          invite.sendEmail = toggled;
          return invite;
        });
      });
    }
  }, [ampli, invitations]);

  const resetData = () => {
    setProcessing(false);
    setContacts([]);
    setInvitations([]);
    setInvalidInvitations([]);
    setCSVError(null);
    setSelectedCsv(null);
  };

  const resetAll = () => {
    resetData();
    resetSteps();
    setSendEmail(true);
  };

  const handleClose = useCallback(
    (closeModal = true) => {
      resetAll();
      if (closeModal) {
        setInviteModalOpen(false);
      }
    },
    [setInviteModalOpen, resetAll],
  );

  const handleContactsChange = (values: string[]) => {
    console.log("** handleContactsChange", values);
    setContacts(values);
  };

  const validateContact = (contact: string) => {
    if (isValidEmail(contact) || isValidPhoneNumber(contact)) {
      // valid input
      return { isError: false, textError: "" };
    } else {
      // invalid input
      return { isError: true, textError: "Invalid email or phone input" };
    }
  };

  const handleCSVUpload = (event) => {
    setCSVError(null);
    setErrorText("");
    const file = csvUploadRef.current.files[0];
    if (file) {
      try {
        // clear out the contacts list
        resetData();
        setParsing(true);
        Papa.parse(file, {
          header: true,
          worker: true,
          skipEmptyLines: "greedy",
          error: (error, file) => {
            console.error("** CSV PARSE ERROR", error, file);
            setCSVError({
              error,
              message: "Upload failed",
            });
          },
          complete: (results) => {
            let invalidRowCount = 0;
            console.log("** CSV PARSE RESULTS", results);
            try {
              const invites: WorkspaceInvitation[] = [];
              results.data?.forEach((member) => {
                const credential =
                  member["Credential (email or phone number)"]?.trim();
                const name = member["fullName"]?.trim();
                const role = member?.["Permission"]?.trim() || defaultRole;
                console.log(role);
                const valid = !validateContact(credential).isError;
                const invite = createInvite(credential, name, role);
                if (valid) {
                  invites.push(invite);
                } else {
                  setInvalidInvitations((prev) => [...prev, invite]);
                  invalidRowCount++;
                }
              });
              const uniqueInvites = invites.reduce((accumulator, current) => {
                const credential = current?.email || current?.phoneNumber;
                if (
                  !accumulator.find(
                    (invite) =>
                      invite.email === credential ||
                      invite.phoneNumber === credential,
                  )
                ) {
                  accumulator.push(current);
                }
                return accumulator;
              }, []);
              setInvitations(uniqueInvites);
            } catch (e) {
              console.error("** CSV PARSE ERROR", e, file);
              setCSVError({
                e,
                message: "Upload failed - Invalid content or column headers",
              });
              setParsing(false);
              return;
            }
            if (results.errors.length > 0) {
              setErrorText(
                `Error with the following rows: ${results.errors
                  .map((e) => e.row)
                  .join(", ")}`,
              );
            } else if (results.data.length === invalidRowCount) {
              setCSVError({ message: "No valid members found" });
            } else {
              setSelectedCsv(file);
            }
            setParsing(false);
          },
        });
        csvUploadRef.current.value = null;
      } catch (e) {
        console.error("** CSV PARSE ERROR", e, file);
        setCSVError({
          e,
          message: "Upload failed",
        });
        setParsing(false);
      }
    }
  };

  const createInvite = (credential, name = "", role) => {
    let invite = {
      name,
      email: null,
      phoneNumber: null,
      role,
      sendEmail: sendEmail,
    } as WorkspaceInvitation;

    if (phoneService.isValidNumber(credential)) {
      //if a number doesn't contain a country code, then default to US
      if (!credential?.includes("+")) {
        credential = `+1${credential}`;
      }
      invite.phoneNumber = credential;
    } else {
      // if not phone number, then assume it's an email, even if invalid for display purposes
      invite.email = credential;
    }

    return invite;
  };

  const handleInviteClick = async () => {
    if (contacts.length || invitations.length) {
      ampli.inviteWorkspaceUser();

      setProcessing(true);
      setError(false);
      setErrorText("");
      try {
        const invitePromises = [];
        const membershipPromises = [];
        if (emails.length > 0) {
          chunkWithMinSize(emails, 300).map((chunk) => {
            invitePromises.push(
              sendWorkspaceInvites(workspaceId, chunk, [], sendEmail),
            );
          });
        }

        if (phoneNumbers.length > 0) {
          chunkWithMinSize(phoneNumbers, 300).map((chunk) => {
            invitePromises.push(sendWorkspaceInvites(workspaceId, [], chunk));
          });
        }

        if (invitations.length > 0) {
          chunkWithMinSize(invitations, 300).map((chunk) => {
            invitePromises.push(
              sendWorkspaceInvites(workspaceId, [], [], sendEmail, chunk),
            );
          });
        }

        const responses: CreateWorkspaceDirectInvitationResponse[] =
          await Promise.all(invitePromises);
        // if were open on a feedId then invite to the channel as well
        if (feedId && responses.length > 0) {
          responses?.map((response) => {
            membershipPromises.push(
              addWorkspaceMembersToFeed(
                workspaceId,
                feedId,
                response?.workspaceMemberships?.map((r) => r.id),
              ),
            );
          });
          await Promise.all(membershipPromises);
        }
        console.warn("** mail/invite/success");
        setStep(2);
        resetData();
      } catch (e) {
        console.warn("** ERR ERR mail/invite/click", e);
        setError(true);
        setErrorText(mailErrorText);
        setProcessing(false);
      }
    }
  };

  const handleContactHelperText = useCallback(
    (node) => {
      if (invitesCount > 0 && node?.value === "") {
        setContactHelperText("Double click to edit a contact.");
      } else {
        setContactHelperText(defaultHelperText);
      }
    },
    [invitesCount],
  );

  const CsvUploadLabel = () => {
    const [openTooltip, setTooltipOpen] = useState(false);
    const handleTooltipClose = () => {
      setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
      setTooltipOpen(true);
    };

    const tooltipBox = useRef(null);

    useOnClickOutside(tooltipBox, () => {
      setActiveInstructions(() => false);
    });

    const displayTitle = (
      <Stack
        ref={tooltipBox}
        sx={{
          gap: 1,
          position: "absolute",
          zIndex: 100,
          background: theme.palette.secondary.dark,
          border: `1.5px solid ${theme.palette.info.light}`,
          borderRadius: "8px",
          color: theme.palette.primary.main,
          boxShadow: theme.shadows[1],
          fontSize: theme.typography.pxToRem(14),
          padding: theme.spacing(2),
          left: "160px",
          top: "30px",
          fontWeight: 700,
          width: "400px",
        }}
      >
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
          Adding members by CSV
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          Download and fill out our{" "}
          <Link
            type="download"
            href="/import_csv_template.csv"
            underline="always"
            sx={{
              color: theme.palette.info.light,
              textDecorationColor: "inherit",
            }}
            aria-label={
              Locator.workspaceNav.members.inviteModal.downloadCsvTemplate
            }
          >
            new member template
          </Link>{" "}
          to add large groups of new members by CSV. Credentials that are
          already associated with an active workspace will be ignored.
        </Typography>
      </Stack>
    );

    const [activeInstructions, setActiveInstructions] =
      useState<boolean>(false);

    return (
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          gap: 0.5,
        }}
      >
        <Typography>Add members by CSV</Typography>
        <IconButton
          onClick={() => setActiveInstructions((value) => !value)}
          sx={{
            p: 0,
            borderRadius: "1000px",
            "&:hover:not([disabled])": {
              background: "transparent",
            },
          }}
          aria-label={Locator.workspaceNav.members.inviteModal.csvInfoButton}
        >
          <Icons.Help
            role="img"
            sx={{
              fontSize: "1.25rem",
              color: openTooltip
                ? theme.palette.info.light
                : theme.palette.primary.main,
            }}
          />
        </IconButton>

        {activeInstructions && displayTitle}
      </Stack>
    );
  };

  modalPages.push(
    // page 0
    <>
      <Stack sx={{ width: "100%", gap: 2.5 }}>
        {invitations?.length === 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                fontWeight: 500,
                gap: 1.5,
                pt: { xs: 2, sm: 0 },
              }}
            >
              <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
                Invite new members to workspace
              </Typography>
              <Typography>
                Enter an email address or phone number to invite new members.
              </Typography>
            </Box>
            <StyledChipsInput
              inputRef={handleContactHelperText}
              placeholder="Type or paste emails or phone numbers..."
              aria-placeholder="Type or paste emails or phone numbers..."
              aria-label={
                Locator.workspaceNav.members.inviteModal.inviteTextField
              }
              helperText={contactHelperText || ""}
              value={contacts}
              addOnWhichKey={["Enter", "Tab", ";", ","]}
              validate={validateContact}
              onChange={handleContactsChange}
              renderChip={(Component, key, props) => {
                return (
                  <Component
                    {...props}
                    key={key}
                    deleteIcon={<Icons.Close />}
                  />
                );
              }}
              hideClearAll
              addOnBlur={true}
              disabled={processing || parsing}
            />
          </>
        ) : null}
        {contacts?.length === 0 ? (
          <Stack>
            {!parsing && invitations?.length > 0 ? (
              <Stack sx={{ gap: 1.5 }}>
                <Stack sx={{ alignItems: "center", gap: 3 }}>
                  <Stack sx={{ alignItems: "center", gap: 1.25 }}>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ fontWeight: 700 }}
                      aria-label={
                        Locator.workspaceNav.members.inviteModal
                          .fileUploadAccepted
                      }
                    >
                      File upload accepted
                    </Typography>
                    <Typography sx={{ color: theme.palette.neutral.main }}>
                      Click the button below to add the new members from this
                      file to your workspace.
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    data-testid={Locator.workspaceNav.members.inviteModal.uploadedFile(
                      `${selectedCsv.name}`,
                    )}
                  >
                    <Icons.CheckCircleOutline
                      role="img"
                      sx={{ color: theme.palette.success.main }}
                    />{" "}
                    {selectedCsv.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.neutral.main,
                      maxWidth: 430,
                      textAlign: "center",
                    }}
                  >
                    Credentials that are already associated with an active
                    workspace will be ignored.
                  </Typography>
                </Stack>
                {invalidInvitations?.length > 0 ? (
                  <Box>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        pb: 1,
                      }}
                    >
                      <Icons.InfoOutlined
                        role="img"
                        sx={{ color: theme.palette.error.main }}
                      />
                      {invalidInvitations?.length}{" "}
                      {invalidInvitations?.length === 1 ? "member" : "members"}{" "}
                      of {totalInvites} cannot be added
                    </Typography>
                    <VList
                      className="scrollable-content"
                      style={{
                        padding: "8px",
                        paddingRight: 0,
                        borderRadius: "8px 10px",
                        border: `1.5px solid ${theme.palette.error.main}`,
                        height: "230px",
                      }}
                      shift
                      id="invalidInvitesList"
                      aria-label={
                        Locator.workspaceNav.members.inviteModal.invalidInvites
                      }
                    >
                      {invalidInvitations?.map((invite, index) => {
                        return (
                          <Stack
                            key={`invalid-invite-${invite.email || invite.phoneNumber
                              }-${index}`}
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: "8px",
                              background:
                                index % 2 === 0
                                  ? theme.palette.secondary.main
                                  : "transparent",
                              padding: "8px",
                            }}
                            data-testid={Locator.workspaceNav.members.inviteModal.invalidInvite(
                              invite.email || invite.phoneNumber,
                            )}
                          >
                            <Typography>{invite.name}</Typography>
                            <Typography>
                              {invite.email || invite.phoneNumber}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </VList>
                  </Box>
                ) : null}
              </Stack>
            ) : (
              <Stack sx={{ gap: 2 }}>
                <CsvUploadLabel />
                <Stack
                  sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}
                >
                  <LoadingButton
                    loading={parsing}
                    color="primary"
                    variant="contained"
                    onClick={() => csvUploadRef.current.click()}
                    aria-label={
                      Locator.workspaceNav.members.inviteModal.uploadFileButton
                    }
                    sx={{ maxWidth: 250 }}
                  >
                    Upload a file
                  </LoadingButton>
                  <input
                    ref={csvUploadRef}
                    type="file"
                    name="csv-upload"
                    multiple={false}
                    hidden
                    onChange={handleCSVUpload}
                  />
                  {csvError ? (
                    <Box>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 0.5,
                        }}
                        aria-label={
                          Locator.workspaceNav.members.inviteModal.uploadError
                        }
                      >
                        <Icons.InfoOutlined
                          role="img"
                          sx={{
                            fontSize: "1.25rem",
                            color: theme.palette.error.main,
                            marginTop: "2px",
                          }}
                        />{" "}
                        {csvError?.message}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        color: selectedCsv
                          ? theme.palette.primary.main
                          : theme.palette.neutral.main,
                      }}
                    >
                      {selectedCsv ? selectedCsv.name : "No file selected"}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : null}
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
            gap: 1,
          }}
        >
          <Checkbox
            edge="end"
            color="primary"
            checked={sendEmail}
            disabled={processing}
            onChange={toggleSendEmail}
            aria-label={
              Locator.workspaceNav.members.inviteModal.sendEmailsCheckbox
            }
            sx={{ pl: 0 }}
          />
          <Typography>Send email invites to new members.</Typography>
        </Stack>
      </Stack>
      {error && (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography sx={{ color: theme.palette.error.contrastText }}>
            {errorText}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: 2.5,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClose(invitations?.length > 0 ? false : true)}
          disabled={processing}
          aria-label={Locator.workspaceNav.members.inviteModal.cancelButton}
          sx={{
            width: { xs: "100%", sm: "auto" },
            order: { xs: 1, sm: 0 },
            flexGrow: 1,
            flexBasis: "100%",
          }}
        >
          CANCEL
        </Button>
        <LoadingButton
          loading={processing}
          variant="contained"
          color="primary"
          onClick={handleInviteClick}
          aria-label={Locator.workspaceNav.members.inviteModal.inviteButton}
          sx={{
            width: { xs: "100%", sm: "auto" },
            order: { xs: 0, sm: 1 },
            flexGrow: 1,
            flexBasis: "100%",
          }}
          disabled={invitesCount === 0}
        >
          Invite {invitesCount} {invitesCount === 1 ? "Member" : "Members"}
        </LoadingButton>
      </Box>
    </>,
    // page 1
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          fontWeight: 500,
          gap: 1.5,
        }}
      >
        <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
          Invitation Successful!
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          The people you invited will see <b>{workspace?.name}</b> when they
          login.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: 2.5,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClose(false)}
          aria-label={Locator.workspaceNav.members.inviteModal.inviteMoreButton}
          startIcon={<Icons.ArrowBack role="presentation" />}
          sx={{
            width: { xs: "100%", sm: "auto" },
            order: { xs: 1, sm: 0 },
            flexGrow: 1,
            flexBasis: "100%",
          }}
        >
          Invite More
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClose()}
          aria-label={Locator.workspaceNav.members.inviteModal.doneButton}
          sx={{
            width: { xs: "100%", sm: "auto" },
            order: { xs: 0, sm: 1 },
            flexGrow: 1,
            flexBasis: "100%",
          }}
        >
          Done
        </Button>
      </Box>
    </>,
  );

  return (
    <ModalForm
      open={inviteModalOpen}
      onClose={handleClose}
      maxWidth={740}
      maxHeight={720}
      disableClose={processing}
    >
      {modalPages[currentStep - 1]}
    </ModalForm>
  );
}
