import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import LockIcon from "@mui/icons-material/Lock";
import { Box } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import { DataContext } from "@/models/DataProvider";
import SteeringWheel from "@/components/Icons/SteeringWheel";
import LinkContextMenu from "@/components/WorkspaceChannelList/LinkContextMenu";
import Locator from "@/locator";
import { ChannelList } from "@/components/WorkspaceChannelList/shared";

interface LinkProps {
  channel: ChannelList;
  active: boolean;
  readOnlyMode: boolean;
  workspaceMembershipId: string;
}

export default function WorkspaceChannelLinkItem({
  channel,
  active,
  readOnlyMode,
  workspaceMembershipId,
}: LinkProps) {
  const { handleContextMenu, handleClose, contextMenuItem, contextMenu } =
    UseContextMenu();
  const { joinPublicChannel } = useContext(DataContext);
  const { workspaceId, feedId } = useParams();
  const navigate = useNavigate();
  const channelUrl = `/workspaces/${channel.workspaceId}/feeds/${channel.id}`;
  const [disabled, setDisabled] = useState<boolean>(false);

  const joinChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    await joinPublicChannel(workspaceId, id);
    handleClose();
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };


  return (
    <Link
      data-testid={Locator.workspaceNav.channels.list.testId(channel.id)}
      aria-label={Locator.workspaceNav.channels.list.item}
      to={`${disabled ? "#" : channelUrl}`}
      onContextMenu={handleContextMenu}
      className={`
        workspace-channel-item
        ${active ? "active" : ""}
        ${channel?.joined ? "joined" : "not-joined"}
        ${disabled ? "disabled" : ""}
      `}
      onClick={async (e) => {
        if (contextMenu) {
          e.preventDefault();
          return;
        }
        if (!channel?.joined) {
          e.preventDefault();
          await joinChannel(channel.id, true);
        }
      }}
    >
      <Box
        className={`
          workspace-channel-item-container
          ${channel?.groupId ? "has-group" : ""}
        `}
      >
        <Box className="workspace-channel-item-container-left ">
          {channel?.isPrivate === 1 && !channel?.aliasChannel && (
            <Box className="workspace-channel-item-lock">
              <LockIcon sx={{ fontSize: "1.125rem" }} />
            </Box>
          )}

          <Box sx={{ flexGrow: 1 }} className="truncate" aria-label={channel.title}>
            {channel.title}
          </Box>
        </Box>
        {channel?.groupId && (
          <Box
            className="workspace-channel-item-container-right truncate"
            aria-label={channel.groupName}
          >
            <Box className="group-name">
              {channel.groupName}
            </Box>
          </Box>
        )}
        <Box className="unread-bubble-container">
          <Box
            className={`
              unread-bubble
              ${channel.unread && channel.joined ? "unread" : ""}
              ${channel.unread && !channel.joined ? "unread-not-joined" : ""}
            `}
          />
        </Box>
      </Box>

      {contextMenuItem(
        <LinkContextMenu
          readOnlyMode={readOnlyMode}
          channel={channel}
          workspaceMembershipId={workspaceMembershipId}
          channelUrl={channelUrl}
          handleClose={handleClose}
        />,
      )}
    </Link>
  );
}
