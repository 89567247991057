import { FullItem } from "@/components/FeedMap";
import { Electric } from "@/generated/client";
import { LiveResultContext } from "electric-sql/dist/client/model/model";
import { ResultData } from "electric-sql/dist/frameworks/react/hooks/useLiveQuery";
import { DependencyList } from "react";

export type FeedItemEvent = {
  id: string;
  accountId: string;
  name?: string;
  createdAt: string;
  feedId: string;
  itemId: string;
  contentId?: string;
};

type queryType<Res> = (
  runQueryFn: () => LiveResultContext<Res>,
  dependencies: DependencyList,
) => ResultData<Res>;

export function getLatestFeedItemEvents<Res>(
  useLiveQuery: queryType<Res>,
  db: Electric["db"],
  { item }: { item: FullItem },
) {
  const { results: latestFeedItemEvents, error } = useLiveQuery(() => {
    if (!item.feedId || !item.id) return;

    return db.liveRaw({
      sql: `SELECT
                DISTINCT (ae.accountId || ' ' || ae.name) as groupCol,
                         ae.*
            FROM
                account_event as ae
                    JOIN account as a ON a.id = ae.accountId
            WHERE
                ae.feedId = $1
              AND
                ae.itemId = $2
              AND
                ae.name IS NOT NULL
            GROUP BY groupCol
            ORDER BY ae.createdAt DESC`,
      args: [item.feedId, item.id],
    });
  }, [item]);

  return { latestFeedItemEvents, error } as {
    latestFeedItemEvents: FeedItemEvent[];
    error: any;
  };
}
