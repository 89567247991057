import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { SelectWorkspaceMemberships } from "./memberships/selectWorkspaceMemberships";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";

export default function ChannelUsersModal() {
  const [saving, setSaving] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { db } = useElectric();

  const theme = useTheme();


  const { currentFeedAccounts, currentFeedId } =
    useContext(CurrentFeedContext);

  const { isWorkspaceAdmin, currentWorkspaceId, myCurrentWorkspaceMembership } = useContext(WorkspaceContext);
  const workspaceId = currentWorkspaceId;

  const { myAccountId } = useContext(MyAccountContext);

  const { addWorkspaceMembersToFeed } = useContext(ActionContext);

  const { channelUsersModalOpen, setChannelUsersModalOpen, toggleInviteModal } =
    useContext(UxContext);

  const workspaceAdmin = isWorkspaceAdmin();

  const handleClose = useCallback(() => {
    setChannelUsersModalOpen(false);
  }, [setChannelUsersModalOpen]);

  const handleSelectedUsers = (users: string[]) => {
    // best practice to use a handler as a prop to update a stateful value
    setSelectedUsers(users);
  };

  const handleAddUsers = useCallback(async () => {
    try {
      setSaving(true);
      addWorkspaceMembersToFeed(workspaceId, currentFeedId, [
        ...selectedUsers,
        myCurrentWorkspaceMembership?.id,
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
      setSelectedUsers([]);
      handleClose();
    }
  }, [
    currentFeedId,
    workspaceId,
    selectedUsers,
    addWorkspaceMembersToFeed,
    handleClose,
    myCurrentWorkspaceMembership?.id,
  ]);

  const handleWorkspaceInvite = useCallback(() => {
    handleClose();
    toggleInviteModal();
  }, [toggleInviteModal, handleClose]);

  const alreadyCheckedAccountIds = Array.from(
    currentFeedAccounts?.values() ?? [],
  ).map((account) => account.id);

  return (
    <>
      <ModalForm
        id="add-users-to-channel"
        open={channelUsersModalOpen}
        onClose={handleClose}
        disableClose={saving}
        maxHeight={650}
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              fontWeight: 500,
              gap: 1.5,
            }}
          >
            <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
              Add Users to this Channel
            </Typography>
            <Typography>Select workspace users below.</Typography>
          </Box>
          <SelectWorkspaceMemberships
            saving={saving}
            selectedUsers={selectedUsers}
            workspaceId={workspaceId}
            selectedUsersHandler={handleSelectedUsers}
            handleWorkspaceInvite={handleWorkspaceInvite}
            isWorkspaceAdmin={workspaceAdmin}
            alreadyCheckedAccountIds={alreadyCheckedAccountIds}
            accountIdsToExclude={[myAccountId]}
            theme={theme}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              variant="outlined"
              aria-label={Locator.feed.members.addUsersModal.cancelButton}
              color="primary"
              onClick={() => handleClose()}
              disabled={saving}
              sx={{
                width: { xs: "100%", sm: "auto" },
                order: { xs: 1, sm: 0 },
                flexGrow: 1,
                flexBasis: "100%",
              }}
            >
              CANCEL
            </Button>
            <LoadingButton
              loading={saving}
              disabled={selectedUsers.length === 0}
              aria-label={Locator.feed.members.addUsersModal.completeButton}
              variant="contained"
              color="primary"
              onClick={handleAddUsers}
              sx={{
                width: { xs: "100%", sm: "auto" },
                order: { xs: 0, sm: 1 },
                flexGrow: 1,
                flexBasis: "100%",
              }}
            >
              Add {selectedUsers.length || ""}
              {selectedUsers.length > 1 ? " users" : " user"}
            </LoadingButton>
          </Box>
        </>
      </ModalForm>
    </>
  );
}
