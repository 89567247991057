import type React from "react";
import { Box, MenuItem } from "@mui/material";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Locator from "@/locator";
import ListIcon from "@/components/Icons/ListIcon";
import { useElectric } from "@/electric/ElectricWrapper";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import {
  ChannelList,
  fetchAllGroupedChannels,
  fetchAllNonGroupedChannels,
} from "@/components/WorkspaceChannelList/shared";
import { TrackingContext } from "@/models/TrackingStateProvider";

interface Props {
  channel: ChannelList;
  workspaceMembershipId: string;
  handleClose: () => void;
  channelUrl: string;
  readonlyMode: boolean
}

export default function LinkContextMenu({
  channel,
  workspaceMembershipId,
  handleClose,
  channelUrl,
  readOnlyMode,
}: Props) {
  const { ampli } = useContext(TrackingContext);
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const {
    joinPublicChannel,
    leavePublicChannel,
    leaveFeedGroup,
    joinFeedGroup,
  } = useContext(DataContext);
  const { accountEvent } = useContext(ActionContext);
  const { db } = useElectric();
  const { workspaceId, feedId } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const isGrouped = !!channel?.groupId;

  const markChannelAsRead = async () => {
    accountEvent("Marked Feed as Read", {
      feedId: channel.id,
    });
    setDisabled(() => false);
    handleClose();
  };

  const joinChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    await joinPublicChannel(workspaceId, id);
    handleClose();
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };

  const leaveChannel = async () => {
    setDisabled(() => true);
    await leavePublicChannel(workspaceId, channel.id, workspaceMembershipId);
    const url = await fetchNextActiveChannel();
    const leavingActiveChannel = channel.id === feedId
    if(leavingActiveChannel){
      navigate(url);
    }
    handleClose();
    setDisabled(() => false);
  };

  const joinGroup = async () => {
    setDisabled(() => true);
    ampli.groupJoin({ workspaceId });
    await joinFeedGroup({
      workspaceId,
      feedGroupId: channel.groupId,
    });
    setDisabled(() => false);
    handleClose();
  };

  const leaveGroup = async () => {
    setDisabled(() => true);
    ampli.groupLeave({ workspaceId });
    await leaveFeedGroup({
      workspaceId,
      feedGroupId: channel.groupId,
    });
    const url = await fetchNextActiveChannel();
    setDisabled(() => false);
    if (url) {
      navigate(url);
    }
    handleClose();
  };

  const markAllAsRead = async () => {
    setDisabled(() => true);

    /**
     * If the item is a group only select grouped items
     * If it's not a group only select non-grouped items
     */
    const feedItems = channel?.groupId
      ? await fetchAllGroupedChannels({
          db,
          workspaceMembershipId,
          groupId: channel.groupId,
        })
      : await fetchAllNonGroupedChannels({ db, workspaceMembershipId });

    if (feedItems.length > 0) {
      for (const feedItem of feedItems) {
        accountEvent("Marked Feed as Read", {
          feedId: feedItem.feedId,
        });
      }
    }

    setDisabled(() => false);
    handleClose();
  };

  return (
    <div className="new-channel-item-context-menu">
      {!readOnlyMode && channel?.unread === 1 && channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAsRead}
          disabled={disabled}
          onClick={markChannelAsRead}
        >
          <Box className="new-channel-context-button">
            <DraftsOutlinedIcon />
          </Box>
          <Box>Mark As Read</Box>
        </MenuItem>
      )}
      {!readOnlyMode && channel?.unread === 1 && channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAllAsRead}
          disabled={disabled}
          onClick={markAllAsRead}
        >
          <Box className="new-channel-context-button">
            <ListIcon />
          </Box>
          {
            channel?.groupId
              ? (<Box>Mark Group as read</Box>)
              : (<Box>Mark all as Read</Box>)
          }
        </MenuItem>
      )}

      {isGrouped && !channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.join}
          disabled={disabled}
          onClick={joinGroup}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Join all channels in Group</Box>
        </MenuItem>
      )}

      {isGrouped && channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.groups.leave}
          disabled={disabled}
          onClick={leaveGroup}
        >
          <Box className="new-channel-context-button">
            <LogoutIcon />
          </Box>
          <Box>Leave all channels in Group</Box>
        </MenuItem>
      )}
      {channel?.joined ? (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.leave}
          disabled={disabled}
          onClick={leaveChannel}
        >
          <Box className="new-channel-context-button">
            <LogoutIcon />
          </Box>
          <Box>Leave Channel</Box>
        </MenuItem>
      ) : (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.join}
          disabled={disabled}
          onClick={() => joinChannel(channel.id, false)}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Join Channel</Box>
        </MenuItem>
      )}
    </div>
  );
}
