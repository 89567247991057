import AvatarWithStatus from "@/components/AvatarWithStatus";
import MemberDrawerListItem from "@/components/MemberDrawerListItem";
import { useElectric } from "@/electric/ElectricWrapper";
import { Permission, WorkspaceMembership } from "@/generated/client";
import { ActionContext } from "@/models/ActionsProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import { defaultFocusStyles, menuItemFocusSX } from "@/utils";
import * as Icons from "@mui/icons-material";
import {
  Box,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { throttle } from "throttle-typescript";
import "../assets/menus.css";
import { DrawerHeader } from "./DrawerHeader";
import AddChannelUsersLink from "./Workspace/AddChannelUsersLink";

export interface MembersDrawerProps extends DrawerProps {
  handleClose: () => void;
  url: string | null;
}

export default function MembersDrawerContents({
  sx,
  handleClose,
}: MembersDrawerProps) {
  const { myAccount } = useContext(MyAccountContext);
  const { currentWorkspaceId, workspaceMemberships, isWorkspaceLimitedMember } =
    useContext(WorkspaceContext);
  const {
    currentFeedId,
    isCurrentFeedAdmin,
    currentFeedAccounts,
    currentFeedPendingInvites,
    currentFeed,
  } = useContext(CurrentFeedContext);
  const {
    isSmUp,
    toggleRightNav,
    setChannelModalOpen,
    setChannelModalNew,
    setEditChannelSettings,
  } = useContext(UxContext);

  const { removeFeed } = useContext(ActionContext);
  const { db } = useElectric();
  const limitedMember = isWorkspaceLimitedMember();

  const { results: feedPermissions } = useLiveQuery(() => {
    if (!currentFeedId) {
      return;
    }
    return db.permission.liveMany({
      where: {
        feedId: currentFeedId,
      },
    });
  }, [currentFeedId]);

  const accounts = Array.from(currentFeedAccounts?.values() ?? []);

  // use the feedPermissions size to determine if accounts need to be re-rendered to reflect changes
  const activePermissionChanges = feedPermissions?.filter(
    (perm) => perm?.enabled === 1,
  )?.length;

  const accountDetails = useCallback(
    (
      accountId: string,
    ): {
      isAdmin: boolean;
      isMuted: boolean;
      workspaceMembership: WorkspaceMembership | null;
      permissions: Array<Permission>;
    } => {
      if (!workspaceMemberships || !feedPermissions) {
        return;
      }
      const findWorkspaceMembership = workspaceMemberships.find(
        (membership) => membership.accountId === accountId,
      );
      if (!findWorkspaceMembership) {
        return;
      }
      const accountPermissions = feedPermissions.filter(
        (permission) =>
          permission.workspace_membershipId === findWorkspaceMembership.id,
      );

      return {
        isAdmin: !!accountPermissions.find(
          (permission) =>
            permission?.name === "admin" && permission.enabled === 1,
        ),
        isMuted:
          accountPermissions.filter(
            (permission) =>
              permission.name === "write" && permission.enabled === 1,
          )?.length === 0 && accountPermissions?.length > 1,
        workspaceMembership: findWorkspaceMembership,
        permissions: accountPermissions,
      };
    },
    [
      activePermissionChanges,
      workspaceMemberships?.map((m) => m.id).join(),
      feedPermissions?.map((p) => p.id).join(),
    ],
  );

  const mutatedAccounts = useMemo(() => {
    return accounts?.map((account) => {
      const accountDetail = accountDetails(account?.id);
      return {
        ...account,
        permissions: accountDetail?.permissions,
        workspaceMembership: accountDetail?.workspaceMembership,
        isAdmin: accountDetail?.isAdmin,
        isMuted: accountDetail?.isMuted,
      };
    });
  }, [
    activePermissionChanges,
    accounts?.map((a) => a.id).join(),
    accountDetails,
  ]);

  const theme = useTheme();

  const [membersMenuAnchorEl, setMembersMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [membersMenuAnchorElOpen, setMembersMenuAnchorElOpen] = useState(false);
  const menuAnchorElOpen = Boolean(membersMenuAnchorEl);

  const leaveChannel = useCallback(async () => {
    await removeFeed(currentWorkspaceId, currentFeedId).then(() =>
      toggleRightNav(),
    );
  }, [currentWorkspaceId, currentFeedId, removeFeed, toggleRightNav]);

  const handleLeaveChannel = async () => {
    setMembersMenuAnchorEl(null);
    await leaveChannel();
  };

  const canLeaveChannel = currentFeed?.isPrivate === 1;
  const isPrivateChannel = currentFeed?.isPrivate === 1;
  const isDm = currentFeed?.isDm === 1;

  const _handleKeyUp = (event: KeyboardEvent<HTMLButtonElement>) => {
    const keyCode = event.key.toLowerCase();

    /**
     * @NOTE filter key presses
     *  - when initially focused, this event will fire off of `TAB`
     *  -> in order to keep the illusion that the parent element is opened,
     *     we need to throttle + filter for <space> or <enter>
     */
    if (keyCode === " " || keyCode === "enter") {
      setMembersMenuAnchorElOpen(!membersMenuAnchorElOpen);
    }
  };

  const handleKeyUp = throttle(_handleKeyUp, 100);

  const handleMemberMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setMembersMenuAnchorEl(event.currentTarget);
  };

  const handleMemberMenuClose = () => {
    setMembersMenuAnchorEl(null);
    setMembersMenuAnchorElOpen(false);
    setEditChannelSettings(false);
  };

  const handleChannelSettings = () => {
    setEditChannelSettings(true);
    setChannelModalOpen(true);
    setChannelModalNew(() => false);
  };

  const publicChannel = !isDm && !isPrivateChannel;
  const showAddUserButton = !isDm && isPrivateChannel && isCurrentFeedAdmin;
  const showAddUsersButton =
    (publicChannel && !limitedMember) || showAddUserButton;
  const [panelId, setPanelId] = useState<string | null>(null);

  const MemberListHeader = ({
    children,
  }: {
    children: React.ReactNode;
  }) => (
    <ListSubheader
      sx={{
        p: 0,
        background: theme.palette.secondary.dark,
        width: "100%",
        zIndex: 10,
      }}
    >
      <ListItem
        sx={{
          color: theme.palette.primary.main,
          borderRadius: "8px",
          padding: 1,
          border: "1.5px solid transparent",
        }}
        component="div"
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          component="div"
        >
          {children}
        </Typography>
      </ListItem>
    </ListSubheader>
  );

  return (
    <>
      <DrawerHeader position="top">
        <Stack
          sx={{
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h6" component="h2">
            Members
          </Typography>
          {!limitedMember &&
            !isDm &&
            currentFeedId &&
            (isPrivateChannel || isCurrentFeedAdmin) && (
              <>
                <IconButton
                  color="secondary"
                  aria-label={"Options menu for channel"}
                  onClick={handleMemberMenuOpen}
                  onKeyUp={handleKeyUp}
                  sx={{
                    ...(menuAnchorElOpen ? defaultFocusStyles : {}),
                    color: theme.palette.primary.main,
                  }}
                >
                  <Icons.PendingOutlined role="img" />
                </IconButton>
                <Menu
                  anchorEl={membersMenuAnchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    mt: 1,
                    ...menuItemFocusSX,
                  }}
                  open={menuAnchorElOpen}
                  onClose={handleMemberMenuClose}
                >
                  {isCurrentFeedAdmin && (
                    <MenuItem
                      onClick={handleChannelSettings}
                      key="channel-settings"
                    >
                      <ListItemIcon>
                        <Icons.Settings role="img" />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontWeight: 500 }}
                      >
                        Channel settings
                      </ListItemText>
                    </MenuItem>
                  )}
                  {isPrivateChannel && (
                    <MenuItem
                      disabled={!canLeaveChannel}
                      onClick={handleLeaveChannel}
                      key="leave-channel"
                    >
                      <ListItemIcon>
                        <Icons.ArrowOutward role="img" />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontWeight: 500 }}
                      >
                        Leave channel
                      </ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
        </Stack>
        {!isSmUp && (
          <IconButton
            color="primary"
            onClick={handleClose}
            aria-label="close drawer"
          >
            <Icons.Close role="img" />
          </IconButton>
        )}
      </DrawerHeader>
      <Box
        sx={{
          height: "100%",
          background: theme.palette.secondary.dark,
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <Stack
          sx={{
            p: 2,
            pr: 0.75,
            gap: 3.75,
            height: "100%",
          }}
        >
          {showAddUsersButton && <AddChannelUsersLink />}
          <List
            key={`${currentWorkspaceId}-${currentFeedId}-members`}
            className="scrollable-content"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              gap: 3.75,
              position: "relative",
              pt: 0,
              "& li": {
                width: "100%",
              },
              "& ul": {
                padding: 0,
                width: "100%",
              },
            }}
          >
            <li
              key={`section-${currentWorkspaceId}-${currentFeedId}-active-members`}
            >
              <ul
                key={`${currentWorkspaceId}-${currentFeedId}-active-members-list`}
              >
                <MemberListHeader>
                  <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                    Active members
                  </Typography>
                </MemberListHeader>
                <Box>
                  {mutatedAccounts?.map((account) => {
                    return (
                      <Box
                        key={account?.id}
                        sx={{
                          display: "flex",
                          width: "100%",
                          position: "relative",
                          mb: 1,
                          p: 1,
                          alignItems: "center",
                        }}
                        className={`${
                          account?.id === panelId && "active-parent"
                        }`}
                      >
                        <MemberDrawerListItem
                          isDm={isDm}
                          account={account}
                          myAccount={myAccount}
                          isCurrentFeedAdmin={isCurrentFeedAdmin}
                          isOpen={panelId === account?.id}
                          setDropdownState={(state) =>
                            setPanelId(() =>
                              state.id === panelId ? null : state.id,
                            )
                          }
                        />
                      </Box>
                    );
                  })}
                </Box>
              </ul>
            </li>
            {currentFeedPendingInvites?.length > 0 && (
              <li
                key={`section-${currentWorkspaceId}-${currentFeedId}-pending-members`}
              >
                <ul
                  style={{ padding: 0 }}
                  key={`${currentWorkspaceId}-${currentFeedId}-pending-members-list`}
                >
                  <MemberListHeader>
                    <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                      Pending members
                    </Typography>
                  </MemberListHeader>
                  <Box>
                    {currentFeedPendingInvites?.map((invite) => {
                      return (
                        <Box
                          key={invite?.id}
                          sx={{
                            display: "flex",
                            width: "100%",
                            position: "relative",
                            mb: 1,
                            p: 1,
                            alignItems: "center",
                          }}
                        >
                          <ListItem
                            className="menu-options-list"
                            key={invite?.id}
                            sx={{
                              p: 1,
                            }}
                          >
                            <ListItemIcon>
                              <AvatarWithStatus accountId={null} />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ flexGrow: 0 }}
                              primaryTypographyProps={{
                                fontWeight: 500,
                              }}
                              className="text-ellipsis"
                            >
                              {invite?.email || invite?.phoneNumber}
                            </ListItemText>
                          </ListItem>
                        </Box>
                      );
                    })}
                  </Box>
                </ul>
              </li>
            )}
          </List>
        </Stack>
      </Box>
    </>
  );
}
