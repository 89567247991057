import { BaseOpenTelemetryComponent } from "@opentelemetry/plugin-react-load";
import React from "react";

export const observe = (
  ChildComponent: React.JSXElementConstructor<any>,
): React.JSXElementConstructor<any> => {
  const debug = import.meta.env.VITE_DEBUG_MODE === "true";

  if (debug) {
    const cls = class ObservedChild extends BaseOpenTelemetryComponent {
      public render() {
        return <ChildComponent {...this.props} />;
      }
    };

    Object.defineProperty(cls, "name", { value: ChildComponent.name });

    return cls;
  } else {
    return ChildComponent;
  }
};
