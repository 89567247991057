import { useElectric } from "@/electric/ElectricWrapper";
import { AppContext } from "@/models/AppStateProvider";
import { useConnectivityState, useLiveQuery } from "electric-sql/react";
import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { ArrowDropDown, ArrowDropUp, Check } from "@mui/icons-material";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UserManagementContext } from "@/models/UserManagementContextProvider";
import { DataGridPagination } from "../Pagination";
import { LoadingButton } from "@mui/lab";
import Loading from "../Loading/Index";
import Locator from "@/locator";
import { MappedWorkspaceRole } from "@/utils";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";

export default function WorkspaceMembersTable() {
  const { removeMember, updateMemberRole } = useContext(ActionContext);
  const { availableWorkspaceRoles, getWorkspaceRoleLabel } =
    useContext(DataContext);
  const { myAccount } = useContext(MyAccountContext);
  const { searchFilter, handleSearchFilter, activeSearchField } = useContext(
    UserManagementContext,
  );
  const { ampli } = useContext(TrackingContext);
  const { db } = useElectric();
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);
  const theme = useTheme();

  const [roleMenuEl, setRoleMenuEl] = useState<null | HTMLElement>(null);
  const [roleMenuOpen, setRoleMenuOpen] = useState<string>();
  const [revokingId, setRevokingId] = useState<string>();
  const [updatingMemberId, setUpdatingMemberId] = useState<string>();
  const roleOptions = Array?.from(availableWorkspaceRoles?.values())?.filter(
    (r) => r?.enabled,
  );

  const closeRoleMenu = () => {
    setRoleMenuEl(null);
    setRoleMenuOpen("");
  };

  const { results: workspaceMembers } = useLiveQuery(
    db.liveRaw({
      sql: `
			SELECT 
				workspace_membership.accountId as id, 
				workspace_membership.role, 
				workspace_membership.id as membershipId, 
				account.name, 
				account.email,
				account.phoneNumber
			FROM 
				workspace_membership
			JOIN 
				account 
			ON 
				workspace_membership.accountId = account.id
      		WHERE 
				workspace_membership.workspaceId = ?
			AND 
				workspace_membership.status = 'active'
			ORDER BY 
				account.name ASC`,
      args: [workspaceId],
    }),
  ) as {
    results: {
      id: string;
      membershipId: string;
      name: string;
      role: string;
      email?: string;
      phoneNumber?: string;
      mine?: boolean;
    }[];
  };

  workspaceMembers?.forEach((member) => {
    if (member.id === myAccount?.id) {
      member.mine = true;
    }
  });

  const removeWorkspaceMember = useCallback(
    async (workspaceMembershipId: string) => {
      try {
        setRevokingId(workspaceMembershipId);
        if (workspaceId) {
          ampli.removeWorkspaceAccess();
          await removeMember(workspaceId, [workspaceMembershipId]);
        }
      } catch (e) {
        console.log(e);
      }
      setRevokingId("");
    },
    [ampli, workspaceId, removeMember],
  );

  const updateWorkspaceMemberRole = useCallback(
    async (workspaceId, membershipId, currentRole, newRole) => {
      try {
        setUpdatingMemberId(membershipId);
        if (
          currentRole === "admin" &&
          (newRole === "member" || newRole === "limitedMember")
        ) {
          ampli.downgradePermissionMember();
        }
        if (
          (currentRole === "member" || currentRole === "limitedMember") &&
          newRole === "admin"
        ) {
          ampli.upgradePermissionAdmin();
        }
        await updateMemberRole(workspaceId, [membershipId], newRole);
      } catch (e) {
        console.log(e);
      }
      setUpdatingMemberId("");
    },
    [ampli, updateMemberRole],
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Full name",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Typography fontWeight={700}>{params.row.name}</Typography>
            <Box sx={{ pl: 2 }}>
              {params.row.mine && <Chip label="Me" color="info" />}
            </Box>
          </>
        );
      },
    },
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      sortComparator: gridStringOrNumberComparator,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.email || params.row.phoneNumber,
    },
    {
      field: "role",
      headerName: "Type",
      minWidth: 120,
      maxWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams) => {
        const memberTestId = params.row?.email || params.row?.phoneNumber;
        return (
          <Box>
            {params.row.mine ? (
              <Typography sx={{ textTransform: "capitalize", pr: 4.2, pl: 1 }}>
                {getWorkspaceRoleLabel(params.row.role) || params.row.role}
              </Typography>
            ) : (
              <>
                <IconButton
                  onClick={(e) => {
                    setRoleMenuEl(e.currentTarget);
                    setRoleMenuOpen(params.row.membershipId);
                  }}
                  aria-label={
                    Locator.workspaceNav.members.activeMembers.roleSelect
                  }
                  data-testid={Locator.workspaceNav.members.activeMembers.roleSelectTestId(
                    memberTestId,
                  )}
                  sx={{
                    textTransform: "capitalize",
                    background: "transparent",
                  }}
                >
                  <Typography>
                    {getWorkspaceRoleLabel(params.row.role) || params.row.role}
                  </Typography>
                  {params.row.membershipId === roleMenuOpen ? (
                    <ArrowDropUp role="img" />
                  ) : (
                    <ArrowDropDown role="img" />
                  )}
                </IconButton>
                {roleMenuEl && params.row.membershipId === roleMenuOpen && (
                  <Menu
                    anchorEl={roleMenuEl}
                    open={params.row.membershipId === roleMenuOpen}
                    onClose={closeRoleMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      mt: 1,
                      ml: 1,
                    }}
                    slotProps={{
                      paper: {
                        sx: {
                          width: 185,
                          px: 1,
                          py: 1.5,
                        },
                      },
                    }}
                  >
                    {roleOptions?.map((r) => (
                      <MenuItem
                        sx={{
                          maxHeight: 40,
                          gap: 1,
                          px: 2,
                          py: 1,
                          borderRadius: "4px",
                          background:
                            params.row.role === r?.role
                              ? theme.palette.secondary.main
                              : "transparent",
                          border:
                            params.row.role === r?.role
                              ? "1.5px solid"
                              : "none",
                          borderColor:
                            params.row.role === r?.role
                              ? theme.palette.secondary.light
                              : "none",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                        key={`role-option-${r?.role}`}
                        onClick={() =>
                          updateWorkspaceMemberRole(
                            workspaceId,
                            params.row.membershipId,
                            params.row.role,
                            r?.role,
                          )
                        }
                        disabled={params.row.membershipId === updatingMemberId}
                      >
                        <Stack
                          sx={{
                            position: "relative",
                            maxWidth: 24,

                            alignItems: "center",
                          }}
                        >
                          {params.row.role === r?.role ? (
                            <Check />
                          ) : (
                            <Box sx={{ width: 24 }}></Box>
                          )}
                          {params.row.role !== r?.role &&
                            params.row.membershipId === updatingMemberId ? (
                            <Loading variant="disabled" size="tiny" />
                          ) : null}
                        </Stack>
                        {r?.label || r?.role}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "remove",
      headerName: "",
      width: 115,
      editable: false,
      sortable: false,
      align: "right",
      renderCell: (params: GridRenderCellParams) => {
        const memberTestId = params.row?.email || params.row?.phoneNumber;
        if (!params.row?.mine) {
          return (
            <LoadingButton
              loading={params.row.membershipId === revokingId}
              onClick={() => removeWorkspaceMember(params.row.membershipId)}
              aria-label={
                Locator.workspaceNav.members.activeMembers.revokeButton
              }
              data-testid={Locator.workspaceNav.members.activeMembers.revokeTestId(
                memberTestId,
              )}
              sx={{
                padding: "8px 12px",
                fontSize: "0.875rem",
                background: theme.palette.secondary.dark,
                maxWidth: "85px",
                lineHeight: 1,
              }}
            >
              REMOVE
            </LoadingButton>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1300,
        overflow: "hidden",
        height: "100%",
      }}
    >
      {workspaceMembers?.length > 0 ? (
        <DataGrid
          rows={workspaceMembers}
          columns={columns}
          pagination
          pageSizeOptions={[100, 60, 20]}
          slots={{
            pagination: DataGridPagination,
            toolbar: GridToolbarQuickFilter,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              inputRef: activeSearchField,
              inputProps: {
                placeholder: "Search for members",
              },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 20 },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [searchFilter],
              },
            },
          }}
          onFilterModelChange={handleSearchFilter}
          hideFooter={workspaceMembers?.length === 0}
        />
      ) : (
        <Stack
          sx={{
            width: "100%",
            py: 1.5,
            px: 2,
            background: theme.palette.secondary.main,
            borderRadius: 1.5,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>No members</Typography>
        </Stack>
      )}
    </Box>
  );
}
