import Loading from "@/components/Loading/Index";
import { useElectric } from "@/electric/ElectricWrapper";
import { AppContext } from "@/models/AppStateProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import {
  fetchFeedItemsAndDownloadEvents
} from "@/models/actions/bootstrap";
import NoChannelsAvailable from "@/routes/workspace/NoChannelsAvailable";
import { Box, Stack, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function LoadingWorkspace() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { myActiveFeeds } = useContext(LiveQueryContext);
  const { db } = useElectric();
  const { client } = useContext(AppContext);
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [noChannelsAvailable, setNoChannelsAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    if (myActiveFeeds?.length === 0) {
      setTimeout(() => {
        setNoChannelsAvailable(true);
      }, 250);
    } else if (myActiveFeeds?.length > 0 && !loading) {
      setNoChannelsAvailable(false);
      setLoading(() => true);
      const feedId = myActiveFeeds[0].id;
      fetchFeedItemsAndDownloadEvents(
        client,
        db,
        feedId,
        params.workspaceId,
      ).then(() => {
        navigate(`/workspaces/${params.workspaceId}/feeds/${feedId}`);
        setLoading(() => false);
      });
    }
  }, [myActiveFeeds, params?.workspaceId]);

  if (noChannelsAvailable) {
    return <NoChannelsAvailable />;
  }

  return (
    loading && (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
          data-feed-layout-wrap
        >
          <Stack
            sx={{
              mx: "auto",
              display: "flex",
              justifyContent: "center",
              my: 6,
              maxWidth: "600px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                gap: "28px",
                width: "100%",
                maxWidth: { xs: "calc(100% - 32px)", sm: "415px" },
                padding: 2,
                borderRadius: "8px",
                background: theme.palette.secondary.dark,
              }}
            >
              <Loading size={"small"} />
            </Box>
          </Stack>
        </Box>
      </>
    )
  );
}
