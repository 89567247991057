import {
  createManyWorkspaceMembership,
  createManyWsAccount,
  createManyWsCommandAliases,
  createManyWsEvent,
  createManyWsFeed,
  createManyWsFeedGroup,
  createManyWsFeedGroupMembership,
  createManyWsItem,
  createManyWsPermission,
  createManyWsTemplate,
} from "@/data/workspace";
import { useElectric } from "@/electric/ElectricWrapper";
import { Electric } from "@/generated/client";
import { AppContext } from "@/models/AppStateProvider";
import { initialUnreadItems } from "@/models/actions/initialFeedLoad";
import { Span, trace } from "@opentelemetry/api";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BootstrapLatestUnreadEventPerFeed,
  BootstrapWorkspaceAccounts,
  BootstrapWorkspaceFeedGroupMemberships,
  BootstrapWorkspaceFeedGroups,
  BootstrapWorkspaceFeedItems,
  BootstrapWorkspaceFeedPermissions,
  BootstrapWorkspaceFeeds,
  BootstrapWorkspaceMemberships,
  BootstrapWorkspaceTemplates,
  UserInfoResponse,
  WorkspaceMembership,
  WsAccount,
  WsCommandAlias,
  WsEvent,
  WsFeed,
  WsFeedGroup,
  WsFeedGroupMembership,
  WsItem,
  WsPermission,
  WsTemplate,
} from "../../web-client/api/data-contracts";
import Client from "../../web-client/client";

const waitForWritesBeforeFetchingNextPage = true;

export type BootstrapResponses =
  | BootstrapWorkspaceFeedItems
  | BootstrapWorkspaceMemberships
  | BootstrapWorkspaceFeedPermissions
  | BootstrapWorkspaceFeeds
  | BootstrapWorkspaceTemplates
  | BootstrapWorkspaceAccounts
  | BootstrapWorkspaceFeedGroups
  | BootstrapWorkspaceFeedGroupMemberships
  | BootstrapLatestUnreadEventPerFeed;

export type CreateManyPayloads =
  | WorkspaceMembership[]
  | WsItem[]
  | WsPermission[]
  | WsFeed[]
  | WsTemplate[]
  | WsAccount[]
  | WsCommandAlias[]
  | WsFeedGroup[]
  | WsFeedGroupMembership[]
  | WsEvent[];

export type BootstrapEventListType = {
  bootstrapFunctionName: string;
  bootstrapFunctionCallResponseName: string;
  createManyFunctionName: (
    db: Electric["db"],
    values: CreateManyPayloads,
  ) => Promise<any>;
  feed?: boolean;
  wait?: boolean;
  pageSize?: number;
};

const firstBootstrapItems: BootstrapEventListType[] = [
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedPermissions",
    createManyFunctionName: createManyWsPermission,
    bootstrapFunctionCallResponseName: "permissions",
    // pageSize: 1000,
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeeds",
    createManyFunctionName: createManyWsFeed,
    bootstrapFunctionCallResponseName: "feeds",
    feed: true,
    // pageSize: 1000,
  },
];

export const bootstrappedApiCalls: BootstrapEventListType[] = [
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedItemsPaginated",
    createManyFunctionName: createManyWsItem,
    bootstrapFunctionCallResponseName: "items",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceMemberships",
    createManyFunctionName: createManyWorkspaceMembership,
    bootstrapFunctionCallResponseName: "workspaceMemberships",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceTemplates",
    createManyFunctionName: createManyWsTemplate,
    bootstrapFunctionCallResponseName: "templates",
    pageSize: 100,
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceAccounts",
    createManyFunctionName: createManyWsAccount,
    bootstrapFunctionCallResponseName: "accounts",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedGroups",
    createManyFunctionName: createManyWsFeedGroup,
    bootstrapFunctionCallResponseName: "feedGroups",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedGroupMemberships",
    createManyFunctionName: createManyWsFeedGroupMembership,
    bootstrapFunctionCallResponseName: "feedGroupMemberships",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceCommandAliases",
    createManyFunctionName: createManyWsCommandAliases,
    bootstrapFunctionCallResponseName: "commandAliases",
  },
  {
    bootstrapFunctionName: "latestUnreadEventPerFeed",
    createManyFunctionName: createManyWsEvent,
    bootstrapFunctionCallResponseName: "events",
  },
  {
    bootstrapFunctionName: "latestUnreadEventPerFeedForOrganizers",
    createManyFunctionName: createManyWsEvent,
    bootstrapFunctionCallResponseName: "events",
  },
];

export default function BootstrapApplication() {
  const params = useParams();
  const { client } = useContext(AppContext);
  const { db } = useElectric();
  const [bootstrapLoading, setBootstrapLoading] = useState<boolean>(false);
  const [allComplete, setAllComplete] = useState<boolean>(false);
  const [localAllComplete, setLocalAllComplete] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [feedIds, setFeedIds] = useState<string[]>([]);
  const [activeWorkspaceId, setActiveWorkspaceId] = useState<string | null>(
    null,
  );
  const [span, setSpan] = useState(() => {
    const s = trace.getTracer("bootstrap").startSpan("bootstrap");
    s.setAttribute("action", "bootstrap");
    s.setAttribute(
      "bootstrap.awaited",
      waitForWritesBeforeFetchingNextPage ? "true" : "false",
    );
    s.setAttribute("bootstrap.type", "root");
    return s;
  });

  const recursiveBootstrapCall = async ({
    workspaceId,
    client,
    db,
    bootstrapEvent,
    page,
    pageSize = 10000,
    span,
    accountId,
  }: {
    workspaceId: string;
    client: Client;
    db: Electric["db"];
    bootstrapEvent: BootstrapEventListType;
    page: number;
    pageSize: number;
    span: Span;
    accountId: string;
  }) => {
    const networkSpan = trace
      .getTracer("bootstrap")
      .startSpan(
        `bootstrap.network.${bootstrapEvent.bootstrapFunctionName}.page.${page}`,
      );

    networkSpan.setAttribute(
      "bootstrap.function",
      bootstrapEvent.bootstrapFunctionName,
    );
    networkSpan.setAttribute("bootstrap.page", page);
    networkSpan.setAttribute("bootstrap.pageSize", pageSize);
    networkSpan.setAttribute("action", "bootstrap");
    networkSpan.setAttribute("workspaceId", workspaceId);
    networkSpan.setAttribute("accountId", accountId);
    networkSpan.setAttribute("bootstrap.type", "network");

    const additionalResponse: BootstrapResponses = await client[
      bootstrapEvent.bootstrapFunctionName
    ]({
      workspaceId,
      page,
      pageSize,
    });

    const responseData =
      additionalResponse[bootstrapEvent.bootstrapFunctionCallResponseName];

    networkSpan.setAttribute("bootstrap.responseSize", responseData.length);
    networkSpan.end();

    if (bootstrapEvent.feed) {
      const feeds: WsFeed[] = responseData as WsFeed[];
      const idMap = feeds?.map((feed) => feed.id);
      setFeedIds((prev) => [...prev, ...idMap]);
    }

    if (responseData?.length > 0) {
      // START AND END NEW SPAND HERE
      console.log("bootstrapEvent", {
        name: bootstrapEvent.bootstrapFunctionName,
        page,
        pageSize,
        length: responseData.length,
      });
      const subSpan = trace
        .getTracer("bootstrap")
        .startSpan(
          `bootstrap.dbwrite.${bootstrapEvent.bootstrapFunctionName}.page.${page}`,
        );

      subSpan.setAttribute(
        "bootstrap.function",
        bootstrapEvent.bootstrapFunctionName,
      );
      subSpan.setAttribute("bootstrap.page", page);
      subSpan.setAttribute("bootstrap.pageSize", pageSize);
      subSpan.setAttribute("action", "bootstrap");
      subSpan.setAttribute("bootstrap.responseSize", responseData.length);
      subSpan.setAttribute("workspaceId", workspaceId);
      subSpan.setAttribute("accountId", accountId);
      subSpan.setAttribute("bootstrap.type", "dbwrite");

      subSpan.setAttribute(
        "bootstrap.awaited",
        waitForWritesBeforeFetchingNextPage ? "true" : "false",
      );

      if (waitForWritesBeforeFetchingNextPage) {
        await bootstrapEvent.createManyFunctionName(db, responseData);
      } else {
        bootstrapEvent.createManyFunctionName(db, responseData);
      }

      subSpan.end();
    } else {
      console.log(
        "No data found for bootstrap event",
        bootstrapEvent.bootstrapFunctionName,
        bootstrapEvent.bootstrapFunctionCallResponseName,
        additionalResponse,
      );
    }

    if (responseData?.length >= pageSize) {
      return await recursiveBootstrapCall({
        workspaceId,
        client,
        db,
        bootstrapEvent,
        page: page + 1,
        pageSize,
        span,
        accountId,
      });
    } else {
      return true;
    }
  };

  const bootstrapPaginatedApplication = async ({
    client,
    db,
    user,
    workspaceId,
    forceBootstrap,
  }: {
    client: Client;
    db: Electric["db"];
    user: UserInfoResponse;
    workspaceId?: string;
    forceBootstrap: boolean;
  }) => {
    const userId =
      user?.session?.credentials[0]?.CredentialScopes[0]?.accountId;

    setUserId(() => userId);

    if (user?.workspaces?.length === 0) {
      console.error("INVALID USER. PLEASE CONTACT SUPPORT");
      return;
    }
    if (bootstrapLoading && !forceBootstrap) {
      return;
    }

    span.setAttribute("accountId", userId);
    span.setAttribute("workspaceId", workspaceId);

    const localActiveWorkspaceId = workspaceId ?? user.workspaces[0].id;
    setActiveWorkspaceId(() => localActiveWorkspaceId);
    setBootstrapLoading(() => true);
    const bootstrapPromises = [];

    if (firstBootstrapItems?.length > 0) {
      for (const firstBSItem of firstBootstrapItems) {
        await recursiveBootstrapCall({
          workspaceId,
          client,
          db,
          bootstrapEvent: firstBSItem,
          page: 0,
          pageSize: firstBSItem.pageSize,
          span,
          accountId: userId,
        });
      }
    }

    if (bootstrappedApiCalls?.length > 0) {
      for (const bootstrapEvent of bootstrappedApiCalls) {
        bootstrapPromises.push(
          recursiveBootstrapCall({
            workspaceId,
            client,
            db,
            bootstrapEvent,
            page: 0,
            pageSize: bootstrapEvent.pageSize,
            span,
            accountId: userId,
          }),
        );
      }
    }

    await Promise.all(bootstrapPromises);

    setLocalAllComplete(() => true);
  };

  useEffect(() => {
    const updateLocalFeed = async () => {
      const startTime = Date.now();
      console.log("Start initialUnreadItems");
      const subSpan = trace
        .getTracer("bootstrap")
        .startSpan("initialUnreadItems");
      subSpan.setAttribute("action", "bootstrap");
      subSpan.setAttribute("workspaceId", activeWorkspaceId);
      subSpan.setAttribute("accountId", userId);

      for (const id of feedIds) {
        initialUnreadItems(db, id, userId, params.workspaceId);
        // if you are on a feed, load that data in the bootstrap
        // if (params?.feedId && params?.workspaceId && id === params?.feedId) {
        // fetchFeedItemsAndDownloadEvents(client, db, id, params.workspaceId);
        // }
        setFeedIds((prev) => prev.filter((feedId) => feedId !== id));
      }
      subSpan.end();
      console.log("End initialUnreadItems", Date.now() - startTime);
      return feedIds?.length;
    };

    console.log("feedIds", feedIds);

    if (feedIds?.length > 0 && userId && localAllComplete) {
      updateLocalFeed().then(() => {
        span.end();
        setAllComplete(() => true);
      });
    }
  }, [feedIds, userId, db, params, client, localAllComplete, span]);

  return {
    allComplete,
    bootstrapPaginatedApplication,
    activeWorkspaceId,
  };
}
