import { createManyWsEvent } from "@/data/workspace";
import { Electric } from "@/generated/client";
import { downloadFeedItemContent } from "@/models/actions/initialFeedLoad";
import { WorkspaceFeedItemOldestEventsResponse } from "web-client/api/data-contracts";
import Client from "web-client/client";
import { context, propagation, trace } from "@opentelemetry/api";

const EVENTS_PER_PAGE = 10000;

export async function downloadOldestFeedItemEvents(
  client: Client,
  db: Electric["db"],
  itemIds: string[],
  workspaceId: string,
) {
  let page = 0;
  let count = 0;
  const parallelRequests = 1;
  while (true) {
    const promises: Promise<WorkspaceFeedItemOldestEventsResponse>[] = [];

    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .getFeedItemOldestEvents(
            workspaceId,
            itemIds,
            currentPage,
            EVENTS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            await createManyWsEvent(db, r.events);
            return r;
          }),
      );
      page += 1;
    }

    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.events.length,
      0,
    );
    count += currentCount;
    if (currentCount % EVENTS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
}

export const fetchLastTenItems = async (
  db: Electric["db"],
  feedId: string,
): Promise<string[]> => {
  console.log("fetching last ten items");

  const tracer = trace.getTracer("bootstrap");

  const activeContext = propagation.extract(context.active(), {});
  const span = tracer.startSpan("fetchLastTenItems", {}, activeContext);

  const items = await db.item.findMany({
    where: {
      feedId: feedId,
      deletedAt: null,
    },
    orderBy: {
      createdAt: "desc",
    },
    take: 10,
  });
  span.end();
  return items?.map((item) => item.id) ?? [];
};

export const fetchFeedItemsAndDownloadEvents = async (
  client: Client,
  db: Electric["db"],
  feedId: string,
  workspaceId: string,
) => {
  const itemsMap = await fetchLastTenItems(db, feedId);
  if (itemsMap?.length > 0) {
    return await downloadFeedItemContent(client, db, workspaceId, itemsMap);
    // return await downloadOldestFeedItemEvents(
    //   client,
    //   db,
    //   itemsMap,
    //   workspaceId,
    // );
  }
};
