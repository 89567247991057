import AvatarWithStatus from "@/components/AvatarWithStatus";
import { FullInput } from "@/components/Utils";
import {
  type MappedWorkspaceMembership,
  mapWorkspaceMemberships,
  workspaceMemberships,
} from "@/data/liveQueries/workspaceMemberships";
import { useElectric } from "@/electric/ElectricWrapper";
import type { WorkspaceMembership } from "@/generated/client";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import * as Icons from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import Fuse from "fuse.js";
import { useContext, useMemo, useState } from "react";
import { VList } from "virtua";

export function SelectWorkspaceMemberships({
  saving = false,
  selectedUsers,
  selectedUsersHandler,
  handleWorkspaceInvite,
  isWorkspaceAdmin,
  workspaceId,
  theme,
  alreadyCheckedAccountIds = [],
  accountIdsToExclude = [],
  selectType = "checkbox",
}: {
  saving: boolean;
  workspaceId: string;
  selectedUsers: string[];
  selectedUsersHandler: (selectedUsers: string[]) => void;
  handleWorkspaceInvite: () => void;
  isWorkspaceAdmin: boolean;
  theme: any;
  alreadyCheckedAccountIds?: string[];
  accountIdsToExclude?: string[];
  selectType?: string;
}) {
  const { db } = useElectric();

  const [searchField, setSearchField] = useState<string>("");
  const [selectAll, setSelectAll] = useState(false);

  const { currentFeedPendingInvites } = useContext(CurrentFeedContext);

  const pendingInviteIds = currentFeedPendingInvites?.map(
    (invite) => invite?.workspaceMembershipId,
  );

  const { workspaceMembers } = workspaceMemberships(useLiveQuery, db, {
    workspaceId,
  });

  const mappedMembers = mapWorkspaceMemberships(
    workspaceMembers,
    alreadyCheckedAccountIds,
    pendingInviteIds,
  )?.filter((member) => !accountIdsToExclude?.includes(member.accountId));

  // fuse search engine
  const fuse = new Fuse(mappedMembers, {
    // https://www.fusejs.io/examples.html#extended-search rules
    useExtendedSearch: true,
    keys: ["name", "email", "phoneNumber", "membershipId"],
  });

  const searchedMembers = useMemo(() => {
    if (searchField) {
      // `'` denotes includes this search text
      return fuse.search(`'${searchField}`)?.map((result) => result.item);
    }
    return mappedMembers;
  }, [fuse, searchField, mappedMembers]);

  const handleToggle = (id: string) => () => {
    if (selectType === "checkbox") {
      const currentIndex = selectedUsers?.indexOf(id);
      const newChecked = [...selectedUsers];

      if (currentIndex === -1) {
        newChecked.push(id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      selectedUsersHandler(newChecked);
    } else {
      selectedUsersHandler([id]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
    if (selectAll) {
      selectedUsersHandler([]);
    } else {
      const notAlreadySelected = mappedMembers
        ?.filter(
          (member) =>
            !member?.isCurrentFeedMember && !member?.isPendingFeedMember,
        )
        ?.map((member) => member?.membershipId);
      if (notAlreadySelected.length > 0) {
        selectedUsersHandler(notAlreadySelected);
      }
    }
  };

  const secondaryAction = (member: MappedWorkspaceMembership) => {
    if (selectType === "checkbox") {
      return member?.isCurrentFeedMember || member?.isPendingFeedMember ? (
        <Icons.CheckBox
          sx={{ mr: -0.25, color: theme.palette.secondary.light }}
        />
      ) : (
        <Checkbox
          edge="end"
          onChange={handleToggle(member?.membershipId)}
          checked={selectedUsers?.indexOf(member?.membershipId) !== -1}
          inputProps={{
            "aria-labelledby": member?.membershipId,
            disabled: saving,
          }}
          color="primary"
        />
      );
    }
    return (
      <Radio
        edge="end"
        onChange={handleToggle(member?.membershipId)}
        checked={selectedUsers.indexOf(member?.membershipId) !== -1}
        inputProps={{
          "aria-labelledby": member?.membershipId,
          disabled: saving,
        }}
        color="primary"
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <FullInput
          id="workspace-name"
          placeholder="Search workspace members"
          disabled={saving}
          value={searchField}
          callback={(e) => setSearchField(e.target.value)}
        />
      </Box>
      <Stack sx={{ height: "100%", width: "100%", gap: 1 }}>
        {!searchField ? (
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "auto",
              justifyContent: "space-between",
              textTransform: "none",
              px: 1.5,
              pr: "35px",
              py: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            aria-label={Locator.feed.members.addUsersModal.selectAll}
            onClick={handleSelectAll}
            disabled={saving}
          >
            <Typography sx={{ fontWeight: 700 }}>Select all members</Typography>
            <Checkbox
              edge="end"
              checked={selectAll}
              color="primary"
              sx={{ p: 0 }}
            />
          </Button>
        ) : null}
        <VList
          style={{
            width: "100%",
            height: 260,
            borderRadius: 8,
            border: `1.5px solid ${theme.palette.secondary.light}`,
            boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
            position: "relative",
            background: theme.palette.secondary.dark,
            overflow: searchedMembers?.length > 0 ? "auto" : "hidden",
            padding: "8px 0",
            paddingRight: "0",
          }}
          className="scrollable-content"
        >
          {searchedMembers?.length > 0 ? (
            searchedMembers?.map((member, index) => (
              <ListItem
                key={member.membershipId}
                secondaryAction={secondaryAction(member)}
                disablePadding
              >
                <ListItemButton
                  onClick={
                    !member?.isCurrentFeedMember
                      ? handleToggle(member.membershipId)
                      : null
                  }
                  disableRipple
                  disabled={saving}
                  sx={{ p: 1.5 }}
                  aria-label={
                    Locator.feed.members.addUsersModal.addMemberButton
                  }
                  data-testid={Locator.feed.members.addUsersModal.addMemberTestId(
                    member?.email || member?.phoneNumber,
                  )}
                >
                  <ListItemAvatar>
                    <AvatarWithStatus
                      accountId={member?.accountId}
                      member={member}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={member?.membershipId}
                    primary={member?.displayName}
                  />
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <ListItem
              key={"no-results"}
              disablePadding
              sx={{ textAlign: "center" }}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontWeight: 700 }}>
                    No users found
                  </Typography>
                }
              />
            </ListItem>
          )}
        </VList>
        {isWorkspaceAdmin && (
          <Link
            component="button"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 700,
              px: 1.5,
              alignSelf: "flex-start",
            }}
            onClick={handleWorkspaceInvite}
          >
            Invite users to workspace
          </Link>
        )}
      </Stack>
    </>
  );
}
