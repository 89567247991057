import IdleUserModal from "@/components/IdleUserModal";
import ReloadPrompt from "@/components/ReloadPrompt";
import { ElectricWrapper } from "@/electric/ElectricWrapper";
import ActionsProvider from "@/models/ActionsProvider";
import AppStateProvider from "@/models/AppStateProvider";
import AudioAppContextProvider from "@/models/AudioAppContextProvider";
import DataProvider from "@/models/DataProvider";
import LiveQueriesProvider from "@/models/LiveQueriesProvider";
import RouteGuard from "@/models/RouteGuard";
import SchedulingContextProvider from "@/models/SchedulingContextProvider";
import TrackingStateProvider from "@/models/TrackingStateProvider";
import UppyContextProvider from "@/models/UppyContextProvider";
import UxStateProvider from "@/models/UxStateProvider";
import { ConfigData, FlagData } from "@/routes/index";
import Layout from "@/routes/layout";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { ErrorBoundary } from "react-error-boundary";
import { ScrollRestoration, useOutlet } from "react-router-dom";
import Client from "web-client/client";
import { Ampli } from "./ampli";
import ErrorFallback from "./components/ErrorFallback";
import CurrentFeedProvider from "./models/StateProviders/currentFeedProvider";
import MyAccountProvider from "./models/StateProviders/myAccountProvider";
import WorkspaceProvider from "./models/StateProviders/workspaceProvider";
import { TelemetryProvider } from "./models/TelemetryProvider";
import UnreadsContextProvider from "./models/UnreadsContextProvider";

function App({
  config,
  client,
  ampli,
  flags,
}: {
  config: ConfigData;
  client: Client;
  ampli: Ampli;
  flags: FlagData;
}) {
  const outlet = useOutlet();
  const {
    baseDomain,
    tusUrl,
    graphqlUrl,
    graphqlApiKey,
    sessionRecordingLength,
    gaKey,
    env,
    trackingKey,
  } = config;
  console.log("AppRoot", {
    baseDomain,
    tusUrl,
    graphqlUrl,
    graphqlApiKey,
    gaKey,
    env,
    trackingKey,
    flags,
  });
  // useNavigationAndPaintSpan();
  // observeGlobalClicks();

  return (
    <>
      <TelemetryProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Layout>
            <TrackingStateProvider ampli={ampli} />
            <ElectricWrapper>
              <AudioAppContextProvider handsFreeModeFlag={true}>
                <AppStateProvider
                  client={client}
                  baseDomain={baseDomain}
                  tusUrl={tusUrl}
                  graphqlUrl={graphqlUrl}
                  graphqlApiKey={graphqlApiKey}
                  sessionRecordingLength={sessionRecordingLength}
                  initialFlags={flags}
                >
                  <MyAccountProvider>
                    <WorkspaceProvider>
                      <CurrentFeedProvider>
                        <UnreadsContextProvider>
                          <DataProvider client={client}>
                            <LiveQueriesProvider>
                              <UxStateProvider>
                                <ActionsProvider client={client}>
                                  <RouteGuard>
                                    <UppyContextProvider>
                                      <SchedulingContextProvider>
                                        <IdleUserModal />
                                        {outlet}
                                        <Analytics />
                                        <SpeedInsights />
                                        <ReloadPrompt />
                                        <ScrollRestoration />
                                      </SchedulingContextProvider>
                                    </UppyContextProvider>
                                  </RouteGuard>
                                </ActionsProvider>
                              </UxStateProvider>
                            </LiveQueriesProvider>
                          </DataProvider>
                        </UnreadsContextProvider>
                      </CurrentFeedProvider>
                    </WorkspaceProvider>
                  </MyAccountProvider>
                </AppStateProvider>
              </AudioAppContextProvider>
            </ElectricWrapper>
          </Layout>{" "}
        </ErrorBoundary>
      </TelemetryProvider>
    </>
  );
}

export default App;
