import React, { ReactNode } from "react";
import { HoneycombWebSDK } from "@honeycombio/opentelemetry-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { BaseOpenTelemetryComponent } from "@opentelemetry/plugin-react-load";
import { DiagLogger } from "@opentelemetry/api";

class CustomDiagLogger implements DiagLogger {
  debug(...args: any[]): void {
    if (import.meta.env.VITE_DEBUG_MODE === "true") {
      console.log("DEBUG:", ...args);
    }
  }

  error(...args: any[]): void {
    console.error("ERROR:", ...args);
  }

  info(...args: any[]): void {
    console.info("INFO:", ...args);
  }

  verbose(...args: any[]): void {
    console.log("VERBOSE:", ...args);
  }

  warn(...args: any[]): void {
    console.warn("WARN:", ...args);
  }
}

type ObservabilityProps = {
  children: ReactNode | ReactNode[];
  honeycombApiKey: string;
  serviceName: string;
  propagateTraceHeaderCorsUrls?: (string | RegExp)[];
  debug?: boolean;
};

export const Observability = ({
  honeycombApiKey,
  serviceName,
  children,
  debug,
  propagateTraceHeaderCorsUrls,
}: ObservabilityProps) => {
  const configDefaults = {
    ignoreNetworkEvents: true,
    propagateTraceHeaderCorsUrls: [/http:\/\/localhost:3000\/.*/],
  };

  const sdk = new HoneycombWebSDK({
    debug: debug,
    apiKey: honeycombApiKey,
    serviceName: serviceName,
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-document-load": configDefaults,
        "@opentelemetry/instrumentation-fetch": configDefaults,
        // "@opentelemetry/instrumentation-user-interaction": {},
        "@opentelemetry/instrumentation-xml-http-request": configDefaults,
      }),
    ],
  });

  sdk.start();

  // BaseOpenTelemetryComponent.setLogger(new CustomDiagLogger());
  if (debug) {
    console.log(
      "Debug Enabled: Setting up tracer for react-component-lifecycle",
    );
    BaseOpenTelemetryComponent.setTracer("react-component-lifecycle");
  }

  return <>{children}</>;
};
