import Loading from "@/components/Loading/Index";
import { useElectric } from "@/electric/ElectricWrapper";
import UseTimeouts from "@/hooks/useTimeouts";
import { AppContext } from "@/models/AppStateProvider";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import { initialFeedLoad } from "@/models/actions/initialFeedLoad";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useLiveQuery } from "electric-sql/react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function WorkspaceId() {
  const theme = useTheme();
  const { setChannelModalOpen, channelModalOpen } = useContext(UxContext);
  const navigate = useNavigate();
  const { db } = useElectric();
  const { client } = useContext(AppContext);
  const maxDataChecks = 10;
  const [dataChecks, setDataChecks] = useState<number>(0);
  const [showCreateChannel, setShowCreateChannel] = useState<boolean>(false);
  const { availableWorkspaceRoles } = useContext(DataContext);
  const { currentWorkspaceId, myCurrentWorkspaceMembership } =
    useContext(WorkspaceContext);
  const workspaceId = currentWorkspaceId;
  const canCreateChannel =
    myCurrentWorkspaceMembership &&
    myCurrentWorkspaceMembership?.role !==
      availableWorkspaceRoles?.get("limitedMember")?.role;

  const { refresh } = UseTimeouts(1);

  const { results: feeds } = useLiveQuery(() => {
    return db.feed.liveMany({
      where: {
        workspaceId,
      },
    });
  }, [workspaceId]);

  const { myActiveFeeds } = useContext(LiveQueryContext);

  const activeFeedMap = useMemo(
    () =>
      myActiveFeeds
        ?.filter((feed) => feed.workspaceId === workspaceId)
        ?.map((item) => item.id)
        .join("-"),
    [myActiveFeeds, workspaceId],
  );

  const wrapper = (children) => (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          padding: "40px 60px",
          borderRadius: "8px",
          textAlign: "center",
          background: theme.palette.secondary.dark,
        }}
      >
        {children}
      </Box>
    </Box>
  );

  const hasLoadedFeedItems = useRef<boolean>(false);

  useEffect(() => {
    if (!activeFeedMap) {
      return;
    }
    const feedId = myActiveFeeds[0].id;

    if (activeFeedMap && !hasLoadedFeedItems.current) {
      initialFeedLoad(client, db, feedId).then((data) => {
        hasLoadedFeedItems.current = true;
        const redirectUrl = `/workspaces/${workspaceId}/feeds/${feedId}`;
        navigate(redirectUrl);
      });
    }
  }, [activeFeedMap, dataChecks]);

  useEffect(() => {
    if (refresh && dataChecks < maxDataChecks) {
      setDataChecks((value) => value + 1);
    }
    if (
      refresh &&
      dataChecks >= maxDataChecks &&
      !channelModalOpen &&
      !showCreateChannel
    ) {
      setChannelModalOpen(true);
      setShowCreateChannel(() => true);
    }
  }, [refresh]);

  if (showCreateChannel) {
    return wrapper(
      <>
        <Box>
          <Typography
            variant="h6"
            component="h4"
            color={theme.palette.primary.main}
            fontWeight={700}
          >
            No channels available
          </Typography>
          {canCreateChannel ? (
            <Typography color={theme.palette.neutral.main}>
              Please create a channel to get started
            </Typography>
          ) : null}
        </Box>
        {canCreateChannel ? (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => setChannelModalOpen(true)}
              aria-label="Create New Channel"
              sx={{
                width: { xs: "100%", sm: "auto" },
                order: { xs: 0, sm: 1 },
                flexGrow: 1,
                flexBasis: "100%",
              }}
            >
              Create Channel
            </LoadingButton>
          </Box>
        ) : null}
      </>,
    );
  }

  if (
    (feeds?.length === 0 && !activeFeedMap) ||
    (feeds?.length > 0 && !activeFeedMap)
  ) {
    return wrapper(
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2 }}>Loading</Box>{" "}
        <Loading variant="white" size="large" baseSize={6} />
      </Box>,
    );
  }

  if (feeds?.length === 0) {
    return wrapper(
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2 }}>Loading Workspace</Box>{" "}
        <Loading variant="white" size="large" baseSize={6} />
      </Box>,
    );
  }

  if (feeds?.length > 0 && activeFeedMap) {
    return wrapper(
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2 }}>Loading Channel</Box>{" "}
        <Loading variant="white" size="large" baseSize={6} />
      </Box>,
    );
  }

  return wrapper("Error Loading Workspace Channel");
}
