import { Electric } from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";

type ChannelItemResponse = {
  id: string;
  feedId: string;
  groupId: string;
  unread: boolean;
  title: string;
  feedGroupId: string;
  joined: string;
};
export const fetchAllGroupedChannels = async ({
  db,
  workspaceMembershipId,
  groupId,
}: {
  db: Electric["db"];
  workspaceMembershipId: string;
  groupId: string;
}): Promise<ChannelItemResponse[]> => {
  const sql = `
    select
        item.id,
        item.feedId,
        item.groupId,
        item.unread,
        feed.title,
        feed_group_membership.groupId as feedGroupId,
        permission.workspace_membershipId as joined
     from item
        join feed on feed.id = item.feedId
        JOIN permission  ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
        LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
        LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
     where
        unread = 1
        AND feed_group_membership.groupId = $2
        GROUP by item.id
    `;
  return (await db.raw({
    sql,
    args: [workspaceMembershipId, groupId],
  })) as unknown as ChannelItemResponse[];
};

export const fetchAllNonGroupedChannels = async ({
  db,
  workspaceMembershipId,
}: { db: Electric["db"]; workspaceMembershipId: string }): Promise<
  ChannelItemResponse[]
> => {
  const sql = `
      select
          item.id,
          item.feedId,
          item.groupId,
          item.unread,
          feed.title,
          feed_group_membership.groupId as feedGroupId,
          permission.workspace_membershipId as joined
      from item
          join feed on feed.id = item.feedId
          JOIN permission  ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
          LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
          LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
      where
          unread = 1
          AND feed_group_membership.groupId IS NULL
      GROUP by item.id
  `;
  return (await db.raw({
    sql,
    args: [workspaceMembershipId],
  })) as unknown as ChannelItemResponse[];
};

export type ChannelOrderType = "driver-activity" | "alpha-asc";
export type ChannelTypeType =
  | "my-channels"
  | "my-unreads"
  | "all-channels"
  | "all-unreads";
export type ChannelList = {
  id: string;
  title: string;
  workspaceId: string;
  isPrivate: number;
  enabled: boolean;
  groupId: string;
  groupName: string;
  latestActivity: string;
  joined: boolean;
  unread: number;
  driverActivity: string;
  aliasChannel: string;
};

const buildSQL = (
  channelType: ChannelTypeType,
  channelOrder: ChannelOrderType,
  searchValue: string,
  limitedMember: boolean,
): string => {
  // if you are not a limited member and the active tab is all-channels, we need to left join the permission table
  const joinOrLeftJoin =
    !limitedMember && channelType === "all-channels" ? "LEFT JOIN" : "JOIN";

  const escapedSearchValue = searchValue?.replace(/'/g, "''");

  // search query LIKE as ft search
  const searchWhereClause =
    searchValue?.length > 0
      ? `AND (REPLACE(feed.title,' ','') like '%${escapedSearchValue}%' OR feed.title like '%${escapedSearchValue}%' OR feed.id LIKE '%${escapedSearchValue}%' OR feed_group.name LIKE '%${escapedSearchValue}%' OR REPLACE(feed_group.name,' ','') LIKE '%${escapedSearchValue}%')`
      : "";

  // keep alive is a special flag that will keep the My Unreads active after marking all items as read
  const unreadQuery = channelType === "my-unreads" ? "AND (unread = 1)" : "";

  // basic channel sorting -> Driver activity, alias channel and then  latestActivity || alpha-asc
  // if joined is NULL sort to the bottom
  const channelSortOrder =
    channelOrder === "driver-activity"
      ? "ORDER BY IFNULL(joined,9999), driverActivity DESC, aliasChannel desc, latestActivity DESC"
      : "ORDER BY lower(feed.title) ASC";

  // return the sql query
  return `
    SELECT
        feed.id,
        feed.title,
        feed.isPrivate,
        feed.workspaceId,
        permission.enabled,
        feed_group_membership.groupId,
        feed_group.name as groupName,
        permission.enabled as joined,
        MAX(coalesce(feed.updatedAt, '0'),coalesce(MAX(item.createdAt), '0')) as latestActivity,
        EXISTS(select i.id from item as i where i.unread = true AND feed.id = i.feedId LIMIT 1) as unread,
        (SELECT MAX(item.createdAt) FROM item JOIN workspace_membership ON item.accountId = workspace_membership.accountId WHERE item.feedId = feed.id AND workspace_membership.role = 'limitedMember') as driverActivity,
        (SELECT alias FROM workspace_command_alias WHERE workspace_command_alias.feedId = feed.id LIMIT 1) as aliasChannel
    FROM
        feed
          LEFT JOIN item  ON feed.id = item.feedId
          ${joinOrLeftJoin} permission ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
          LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
          LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
    WHERE
        feed.workspaceId = $2
      AND
        feed.isDm = 0
      AND
        (
            (feed.isPrivate = 1 AND permission.enabled = true)
                OR
            (feed.isPrivate = 0)
        )
        ${searchWhereClause}
        ${unreadQuery}
    group by feed.id
    ${channelSortOrder}`;
};

export const fetchChannels = ({
  db,
  channelOrder,
  channelType,
  workspaceId,
  workspaceMembershipId,
  searchValue,
  limitedMember,
}): ChannelList[] => {
  const { results: data } = useLiveQuery(
    () =>
      db.liveRaw({
        sql: buildSQL(channelType, channelOrder, searchValue, limitedMember),
        args: [workspaceMembershipId, workspaceId],
      }),
    [
      channelOrder,
      channelType,
      workspaceId,
      workspaceMembershipId,
      searchValue,
      limitedMember,
    ],
  );

  return data as ChannelList[];
};
