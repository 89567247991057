import Locator from "@/locator";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Button, ButtonProps } from "@mui/material";
import { useContext } from "react";

export interface AddChannelUsersLinkProps extends ButtonProps {
	setCopied?: () => void;
}

export default function AddChannelUsersLink({
	variant = "contained",
	setCopied,
	sx,
}: AddChannelUsersLinkProps) {
	const { currentFeed, isCurrentFeedAdmin } = useContext(CurrentFeedContext);
	const { ampli } = useContext(TrackingContext);

	const { channelUsersModalOpen, toggleChannelUsersModal } =
		useContext(UxContext);

	const handleClick = () => {
		/** @TODO ampli analytics */
		toggleChannelUsersModal();

		const teamName = currentFeed.title;
		const feedId = currentFeed.id;
		const payload = {
			feedId,
			teamName,
		};
		ampli.addNewUsers(payload);
	};

	if (isCurrentFeedAdmin && currentFeed?.isPrivate === 1 || currentFeed?.isPrivate === 0) {
		return (
			<Button
				aria-label={Locator.feed.members.addUsersButton}
				onClick={handleClick}
				variant={variant}
				disableFocusRipple
				sx={sx}
			>
				Add users
			</Button>
		);
	}
}
